import React, { ReactElement } from "react";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import Tooltip from "@mui/material/Tooltip";
import { DISABLED_COLOR } from "../../utils/constants/constantColor";
import { PlacedPosition } from "../../utils/enum/enum";

type BasicTooltipProps = {
  text: string | ReactElement | undefined | ReactElement[];
  icon?: React.ReactElement;
  position?: PlacedPosition;
};
export default function BasicTooltip({
  text,
  icon = (
    <HelpOutlinedIcon
      sx={{
        color: DISABLED_COLOR,
        cursor: "pointer",
      }}
    />
  ),
  position = PlacedPosition.RIGHT,
}: BasicTooltipProps) {
  return (
    <Tooltip title={text} arrow placement={position} sx={{ cursor: "pointer" }}>
      {icon}
    </Tooltip>
  );
}
