import { styled } from "@mui/material";
import customTheme from "../theme/customTheme";

export default styled("section")(() => ({
  backgroundColor: "#f8f8f8",
  padding: "1.25rem",
  borderRadius: "0.9375rem",
  "& a": {
    color: "inherit",
    textDecoration: "none",
  },
  "& a:hover": {
    "& div.MuiTypography-root": {
      color: customTheme.palette.primary.main,
    },
    "& svg": {
      color: customTheme.palette.primary.main,
    },
  },
  "& .MuiCardContent-root": {
    padding: "1.5rem",
  },
  "& div.MuiTypography-root": {
    fontWeight: "400",
    marginBottom: "0rem",
  },
}));
