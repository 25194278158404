import React, { ReactChild } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { ComponentSize } from "../../../utils/enum/enum";

const WrapperContent = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  "& .MuiFormControl-root": {
    width: "100%",
    marginBottom: "1rem",
    "&:last-child": {
      marginBottom: "0",
    },
  },
}));
export type CommonDialogProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: ReactChild;
  textContent?: string;
  size?: ComponentSize;
}>;
export default function CommonDialog({
  title,
  children,
  isOpen,
  onClose,
  size = ComponentSize.Sm,
}: CommonDialogProps) {
  const handleDialogClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onClick={handleDialogClick}
      aria-labelledby="responsive-dialog-title"
      maxWidth={size}
    >
      <WrapperContent>
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            position: "relative",
          }}
        >
          {title}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              borderTop: "0.0625rem solid rgba(0, 0, 0, 0.12)",
              paddingTop: "1rem",
            }}
          >
            {children}
          </div>
        </DialogContent>
      </WrapperContent>
    </Dialog>
  );
}
