// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { FieldArray, FormikProps, FormikProvider } from "formik";
import {
  FormControl,
  MenuItem,
  Select,
  Grid,
  Box,
  Button,
  SelectChangeEvent,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {} from "../../../utils/validations/validations";
import HelperText from "../../../components/HelperText/HelperText";
import Label from "../../../components/Label/Label";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import customTheme from "../../../theme/customTheme";
import { ExtensionApiResponse } from "../../../redux/api/api.types";
import { InitialValues, Integration } from "./integrationFormTypes";

export type IntegraionsType = {
  formik: FormikProps<InitialValues>;
  extensionData: ExtensionApiResponse | undefined;
  foundIntegrationLength: number;
};

export default function Integrations({
  formik,
  extensionData,
  foundIntegrationLength,
}: IntegraionsType) {
  const [showIntegraionField, setShowIntegrationField] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<string[]>([]);
  //processing for form
  const integrationsLocal = extensionData?.results.filter(
    (item) => item.type === "integration"
  );
  const integrationNames = integrationsLocal?.map(({ name }) => ({
    key: name,
    value: name,
  }));

  useEffect(() => {
    // Extract integration names from formik initial values and set them as selected integrations
    if (formik.values.integrations) {
      const initialIntegrationNames = formik.values.integrations.map(
        (integration: Integration) => integration.integration_name
      );
      setSelectedIntegration(initialIntegrationNames);
    }
  }, [formik.values.integrations]);

  const handleIntegrationNameChange = (
    event: SelectChangeEvent<string>,
    idx: number
  ) => {
    const integraionName = event.target.value as string;
    setSelectedIntegration((prev) => {
      const newSelectedIntegration = [...prev];
      newSelectedIntegration[idx] = integraionName; // Update selected integration for the current field
      return newSelectedIntegration;
    });
  };

  //set the showIntegationFiled if some integration has already been assigned to the instacne
  useEffect(() => {
    if (foundIntegrationLength > 0) setShowIntegrationField(true);
  }, [foundIntegrationLength]);

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name="integrations"
        render={(arrayHelpers) => (
          <>
            {formik.values.integrations.map((item, idx) => (
              <React.Fragment key={idx}>
                {showIntegraionField && (
                  <Grid item xs={12} sx={{ pb: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Label
                          text="Integration Name"
                          error={
                            formik?.touched?.integrations?.[idx]
                              ?.integration_name &&
                            Boolean(
                              (
                                (formik?.errors as any)?.[idx] as {
                                  integration_name: string;
                                }
                              )?.integration_name
                            )
                          }
                        />
                        <FormControl fullWidth>
                          <Select
                            labelId="integration-id"
                            id="integration-id"
                            name={`integrations.${idx}.integration_name`}
                            value={item.integration_name}
                            onChange={(event) => {
                              formik.setFieldValue(
                                `integrations.${idx}.integration_name`,
                                event.target.value
                              );
                              handleIntegrationNameChange(event, idx); // Handle integration name change
                            }}
                          >
                            {integrationNames &&
                              integrationNames.map((integration) => (
                                <MenuItem
                                  key={integration.key}
                                  value={integration.value}
                                >
                                  {integration.value}
                                </MenuItem>
                              ))}
                          </Select>
                          {(
                            (formik?.errors as any)?.[idx] as {
                              integration_name: string;
                            }
                          )?.integration_name && (
                            <HelperText
                              text={
                                (
                                  (formik?.errors as any)?.[idx] as {
                                    integration_name: string;
                                  }
                                )?.integration_name
                              }
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <Label
                          text="Version"
                          error={
                            formik?.touched?.integrations?.[idx]?.tag &&
                            Boolean(
                              (
                                formik?.errors?.integrations?.[idx] as {
                                  tag: string;
                                }
                              )?.tag
                            )
                          }
                        />
                        <Box display="flex">
                          <FormControl fullWidth>
                            <Select
                              labelId="integration-id"
                              id="integration-id"
                              name={`integrations.${idx}.tag`}
                              value={item.tag}
                              onChange={(event) =>
                                formik.setFieldValue(
                                  `integrations.${idx}.tag`,
                                  event.target.value
                                )
                              }
                            >
                              {(
                                (selectedIntegration[idx] &&
                                  integrationsLocal?.find(
                                    (ext) =>
                                      ext.name === selectedIntegration[idx]
                                  )?.tags) ||
                                []
                              ).map((tag) => (
                                <MenuItem key={tag.name} value={tag.id}>
                                  {tag.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {(
                              formik?.errors?.integrations?.[idx] as {
                                tag: string;
                              }
                            )?.tag && (
                              <HelperText
                                text={
                                  (
                                    formik?.errors?.integrations?.[idx] as {
                                      tag: string;
                                    }
                                  )?.tag
                                }
                              />
                            )}
                          </FormControl>
                          <Box
                            onClick={() => arrayHelpers.remove(idx)}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mb: "1rem",
                              ml: "1rem",
                            }}
                            component="span"
                          >
                            <DeleteOutlineOutlinedIcon
                              sx={{
                                fontSize: "1.5rem",
                                color: customTheme.palette.error.main,
                                cursor: "pointer",
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Button
                type="button"
                // disabled={
                //   integrationsLocal?.length ===
                //   formik.values.integrations.length
                // }
                onClick={() => {
                  setShowIntegrationField(true);
                  if (showIntegraionField) {
                    arrayHelpers.push({
                      integration_name: "",
                      tag: "",
                      name: "",
                    });
                    setSelectedIntegration((prev) => [...prev, ""]); // Initialize selected integraion for the new field
                  }
                }}
              >
                + ADD INTEGRATION
              </Button>
            </Grid>
          </>
        )}
      />
    </FormikProvider>
  );
}
