// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/rules-of-hooks */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-param-reassign */
import React, { useState } from "react";
import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Popper,
  Paper,
  ClickAwayListener,
  Fade,
  TableSortLabel,
  Button,
  Box,
  Chip,
  Typography,
  Tooltip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import SurroundSoundOutlinedIcon from "@mui/icons-material/SurroundSoundOutlined";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PauseIcon from "@mui/icons-material/Pause";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import { useNavigate } from "react-router-dom";
import { selectPollingInterval } from "../../redux/pollingSlice";
import BackdropSpinner from "../../components/Backdrop/BackdropSpinner";
import PollingInterval from "../../components/PollingInterval/PollingInterval";
import {
  useDeleteInstanceMutation,
  useGetInstanceByIdQuery,
  useGetInstancePaginatedQuery,
  useGetInstanceQuery,
  useLaunchInstanceMutation,
  usePauseInstanceMutation,
} from "../../redux/api/instanceApi";
import usePagination from "../../hooks/usePagination";
import { useGetCustomersQuery } from "../../redux/api/customerApi";
import customTheme from "../../theme/customTheme";
import Styled from "../../theme/CustomMenu/customMenu";
import StyledTable from "../../theme/CustomTable/CustomTable";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import { useAppSelector } from "../../hooks/storeHooks";
import { setTableSeting } from "../../redux/settingSlice";
import { ApplicationTypes } from "../../utils/enum/enum";
import {
  useAssignExtensionMutation,
  useGetExtensionImagesQuery,
} from "../../redux/api/extensionApi";
import useDebounce from "../../hooks/useDebounce";
import Search from "../../components/Search";
import InstanceStatusDialogue from "./InstanceStatusDialogue/InstanceStatusDialogue";
import FindDevice from "./FindDevice/FindDevice";
import AddExtension from "./AddExtension/AddExtension";
import Devices from "./Devices/Devices";
import { calcLicenceExpiration } from "../../utils/functions/functions";

enum SortingDirectionEnum {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

type SortingDirection = `${SortingDirectionEnum}`;

type HeadCell = {
  id: string;
  label: string;
  isSorting: boolean;
};

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    label: "Instance",
    isSorting: true,
  },
  {
    id: "CustomerName.0",
    label: "Customer",
    isSorting: true,
  },
  {
    id: "subscription_leaf_location_limit",
    label: "Location Limit",
    isSorting: true,
  },
  // {
  //   id: "parameters.django.cuname",
  //   label: "Username",
  //   isSorting: true,
  // },

  {
    id: "parameters.django.cuemail",
    label: "Email",
    isSorting: true,
  },

  {
    id: "frontend_url",
    label: "Radea Url",
    isSorting: false,
  },
  {
    id: "subscription_valid_until",
    label: "Licence Expiration",
    isSorting: true,
  },
  {
    id: "subscription_metadata.contactPerson",
    label: "Contact",
    isSorting: true,
  },
  {
    id: "Action",
    label: "Action",
    isSorting: true,
  },
  {
    id: "Status",
    label: "Status",
    isSorting: true,
  },
];

const intervalOptions = [
  { label: "Off", value: 0 },
  { label: "1s", value: 1000 },
  { label: "2s", value: 2000 },
  { label: "3s", value: 3000 },
  { label: "5s", value: 5000 },
  { label: "10s", value: 10000 },
  { label: "1m", value: 60000 },
];

export default function InstanceManagement() {
  //for pagination
  const {
    rowsPerPage,
    page,
    setPage,
    handleChangeRowsPerPage,
    handleChangePage,
  } = usePagination();
  //for devices
  const [showDevices, setShowDevices] = useState(false);
  //for extensions
  const [assignExtension] = useAssignExtensionMutation();
  const { data: extensionData } = useGetExtensionImagesQuery();
  // Filter objects with names "radeaio-core-backend" and "radeaio-core-ui to later extract tags at index 0"

  const backendTags = extensionData?.results?.find(
    (obj) => obj?.name === ApplicationTypes.BACKEND
  )?.tags;

  const backendLatestTag = backendTags
    ? backendTags[0]?.name === "latest"
      ? backendTags[1]?.id
      : backendTags[0]?.id
    : "";

  const frontendLatestTag = extensionData?.results?.filter(
    (obj) => obj?.name === ApplicationTypes.FRONTEND
  )?.[0]?.tags[0]?.id;

  //for extension end
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [showFindDevice, setShowFindDevice] = useState(false);
  const [showInstanceStatusDialog, setShowInstanceStatusDialog] =
    useState(false);
  const { value } = useAppSelector(selectPollingInterval);
  const [name, setName] = React.useState("");
  const debounceValue = useDebounce(name, 700);
  const { enqueueSnackbar } = useSnackbar();
  const { dense } = useAppSelector(setTableSeting);
  const { data: Customers } = useGetCustomersQuery({
    debounceValue: "",
    rowsPerPage: null,
  });
  const { data: Instance } =
    page > 1
      ? useGetInstancePaginatedQuery(
        { page, rowsPerPage },
        { pollingInterval: value }
      )
      : useGetInstanceQuery(
        { debounceValue, rowsPerPage },
        {
          pollingInterval: value,
        }
      );

  const [launchInstance, { isLoading }] = useLaunchInstanceMutation();
  const [handleDelete] = useDeleteInstanceMutation();
  const [handlePause] = usePauseInstanceMutation();
  const [order, setOrder] = useState<SortingDirection>(
    SortingDirectionEnum.DESCENDING
  );
  const [orderBy, setOrderBy] = useState("name");

  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [rowId, setRowId] = useState("");
  const [editId, setEditId] = useState("");

  const { data: singleInstance } = useGetInstanceByIdQuery(rowId, {
    refetchOnMountOrArgChange: true,
  });

  //collect non applications extension and integrations

  const integrationsData =
    singleInstance?.assigned_instance_tags?.extensions?.integrations;
  const verticalData =
    singleInstance?.assigned_instance_tags?.extensions?.verticals;

  const assignedIntegrationTags = Object?.values(integrationsData || {})?.map(
    (item) => item?.tag_id
  );
  const assignedVerticalTags = Object?.values(verticalData || {})?.map(
    (item) => item?.tag_id
  );

  //assigned tags for applications. Below if a value is selected then it will be used else the latest tag will be used

  const allApplications = singleInstance?.assigned_instance_tags?.applications;

  const assignedFrontendApplicationTag = allApplications?.[
    ApplicationTypes.FRONTEND
  ]?.tag_id
    ? allApplications?.[ApplicationTypes.FRONTEND]?.tag_id
    : frontendLatestTag;

  const assignedBackendApplicationTag = allApplications?.[
    ApplicationTypes.BACKEND
  ]?.tag_id
    ? allApplications?.[ApplicationTypes.BACKEND]?.tag_id
    : backendLatestTag;

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | any>(null);

  const handleFindDialog = () => {
    setShowFindDevice(false);
  };

  const handleRedirect = (url: string) => {
    // navigate("/external-link", { state: { frontend_url: url } });
    window.open(url, "_blank");
  };

  const handleCopyUrl = (rowId: string) => {
    Instance?.results?.forEach((data) => {
      if (data.id === rowId) {
        navigator.clipboard.writeText(data.frontend_url);
      }
    });
    setOpen(false);
    setRowId("");
  };

  function flattenObject(obj: any, newObj: any, prefix: any) {
    newObj = newObj || {};
    prefix = prefix || "";
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const type = typeof obj[key];
        const newKey = prefix ? `${prefix}.${key}` : key;
        if (type === "string" || type === "number" || type === "boolean") {
          newObj[newKey] = obj[key];
        } else if (type === "object") {
          flattenObject(obj[key], newObj, newKey);
        }
      }
    }
    return newObj;
  }

  const flattenInstance = Instance?.results?.map((data) => {
    const customerObject = Customers?.results.map((customer) => {
      if (customer.id === data.customer) {
        return customer.name;
      }
      return null;
    });
    const nonNullCustomer = customerObject?.filter((co) => co !== null);
    const newData = { ...data, CustomerName: nonNullCustomer };
    return flattenObject(newData, {}, "");
  });

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    // Check if values are strings before using localeCompare
    if (typeof aValue === "string" && typeof bValue === "string") {
      return bValue.localeCompare(aValue);
    }

    // Fallback to regular comparison if values are not strings
    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  }

  function getComparator(order: SortingDirection, orderBy: string) {
    return order === SortingDirectionEnum.ASCENDING
      ? (a: Record<string, unknown>, b: Record<string, unknown>) =>
        descendingComparator(a, b, orderBy)
      : (a: Record<string, unknown>, b: Record<string, unknown>) =>
        -descendingComparator(a, b, orderBy);
  }

  const handleDeleteDialog = () => {
    setConfirmationDialog(false);
  };
  const handleInstanceStatusDialog = () => {
    setShowInstanceStatusDialog(false);
    setEditId("");
    setRowId("");
    setOpen(false);
  };

  //for extensions
  const handleCloseDialog = () => {
    setEditId("");
    setRowId("");
    setShowDialog(false);
    setOpen(false);
  };

  const handleDeleteInstance = (rowId: string) => {
    handleDelete(rowId)
      .unwrap()
      .then((payload) => {
        if (payload) {
          enqueueSnackbar("Instance Deleted", {
            variant: "success",
            autoHideDuration: 2000,
          });
        }
      })
      .catch((error) => {
        if (error) {
          enqueueSnackbar(`Failed to delete, ${error?.data?.detail}`, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    setConfirmationDialog(false);
    setRowId("");
  };

  const handlePauseInstance = (rowId: string) => {
    handlePause(rowId)
      .unwrap()
      .then((payload) => {
        if (payload) {
          enqueueSnackbar("Instance Paused", {
            variant: "success",
            autoHideDuration: 2000,
          });
        }
      })
      .catch((error) => {
        if (error) {
          enqueueSnackbar(
            `Failed to paused, try again, ${error?.data?.detail}`,
            {
              variant: "error",
              autoHideDuration: 5000,
            }
          );
        }
      });
    setOpen(false);
    setRowId("");
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleClose = () => {
    setOpen(false);
    setRowId("");
  };

  //get instance status for launch code when instance is paused

  const getPausedStatus = () => {
    if (singleInstance?.is_launched && singleInstance?.status === "paused") {
      return true;
    }
    return false;
  };

  const handleLaunchInstance = (rowId: string) => {
    if (getPausedStatus()) {
      launchInstance(rowId)
        .unwrap()
        .then((payload) => {
          if (payload) {
            enqueueSnackbar("Instance Initiated", {
              variant: "success",
              autoHideDuration: 2000,
            });
          }
        })
        .catch((error) => {
          if (error) {
            enqueueSnackbar(
              `Instance Failed to initiate, ${error?.data?.detail}`,
              {
                variant: "error",
                autoHideDuration: 2000,
              }
            );
          }
        });
    } else {
      const allTags = [
        assignedFrontendApplicationTag,
        assignedBackendApplicationTag,
        ...assignedIntegrationTags,
        ...assignedVerticalTags,
      ];
      const dataToSubmit = { tag_ids: allTags };
      assignExtension({ dataToSubmit, id: rowId })
        .unwrap()
        .then(() => {
          enqueueSnackbar("Extensions assigned to the instance.", {
            variant: "success",
            autoHideDuration: 2000,
          });
          launchInstance(rowId)
            .unwrap()
            .then((payload) => {
              if (payload) {
                enqueueSnackbar("Instance Initiated", {
                  variant: "success",
                  autoHideDuration: 2000,
                });
              }
            })
            .catch((error) => {
              if (error) {
                enqueueSnackbar(
                  `Instance Failed to initiate, ${error?.data?.detail}`,
                  {
                    variant: "error",
                    autoHideDuration: 2000,
                  }
                );
              }
            });
        })
        .catch((error) => {
          enqueueSnackbar(`Failed to add extensions, ${error?.data?.detail}`, {
            variant: "error",
            autoHideDuration: 2000,
          });
        });
    }
    setOpen(false);
    setRowId("");
  };

  const handleContextMenu = (e: React.MouseEvent, rowId: string) => {
    e.preventDefault();
    e.stopPropagation();
    const { clientX, clientY } = e;
    setOpen(true);
    setRowId(rowId);
    setEditId(rowId);
    const virtualElement = {
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        top: clientY,
        right: clientX,
        bottom: clientY,
        left: clientX,
      }),
    };
    setAnchorEl(virtualElement);
  };
  const id = open ? "instance-managment-menu" : undefined;

  // get background color of table row
  function getTableRowColor(
    rowId: string | number,
    selectedRowId: string | number | null
  ) {
    const highlightColor = customTheme.palette.secondary.main;
    const defaultColor = customTheme.palette.secondary.light;
    if (selectedRowId) {
      return selectedRowId === rowId ? highlightColor : defaultColor;
    }
    return defaultColor;
  }

  function getStatusColors(status: string) {
    if (status === "running") {
      return "success";
    }
    if (status === "not initialized") {
      return "warning";
    }
    if (status === "deleting") {
      return "error";
    }
    return "info";
  }

  function getLaunchStatus() {
    if (
      singleInstance?.is_launched &&
      singleInstance?.status !== "initializing failed" &&
      singleInstance.status !== "paused"
    ) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line consistent-return
  function getPauseStatus() {
    if (
      singleInstance?.is_launched &&
      singleInstance?.status === "pausing failed"
    ) {
      return false;
    }
    if (singleInstance?.is_launched) {
      if (
        singleInstance?.status === "paused" ||
        singleInstance.status === "initializing" ||
        singleInstance?.status !== "running"
        // singleInstance?.status !== "pausing failed" ||
      ) {
        return true;
      }
      return false;
    }

    if (!singleInstance?.is_launched) {
      if (singleInstance?.status === "not initialized") {
        return true;
      }
    }
    if (!singleInstance?.is_launched) {
      if (singleInstance?.status === "initializing failed") {
        return true;
      }
    }
  }
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setPage(1);
  };

  const handleDevices = () => {
    setEditId("");
    setRowId("");
    setShowDevices(false);
    setOpen(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {isLoading && <BackdropSpinner open={isLoading} />}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Search name={name} handleSearch={handleSearch} />
          <Button
            variant="contained"
            onClick={() => {
              navigate("/add-edit-instance", {
                state: { isInitialized: false, id: "" },
              });
              setEditId("");
            }}
            sx={{ ml: "2rem" }}
          >
            <AddCircleIcon sx={{ marginRight: "0.5rem" }} /> Create Instance
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="text"
            onClick={() => {
              setShowFindDevice(true);
            }}
            sx={{ mr: "1rem" }}
          >
            <MyLocationOutlinedIcon sx={{ marginRight: "0.5rem" }} /> Find
            Device
          </Button>
          <Box sx={{ display: "flex", justifyContent: "flex-end", ml: "10px" }}>
            <PollingInterval options={intervalOptions} />
          </Box>
        </Box>
      </Box>
      <StyledTable>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            sx={dense ? { marginTop: "1rem" } : null}
            style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow>
                {headCells.map((item) => (
                  <TableCell key={item.id}>
                    {item.isSorting ? (
                      <TableSortLabel
                        active={orderBy === `${item.id}`}
                        direction={orderBy === `${item.id}` ? order : "asc"}
                        onClick={createSortHandler(item.id)}
                      >
                        {item.label}
                      </TableSortLabel>
                    ) : (
                      item.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {flattenInstance &&
                flattenInstance
                  ?.slice(rowsPerPage * 0, rowsPerPage * (page + 1))
                  ?.sort(getComparator(order, orderBy))
                  .map((data) => (
                    <TableRow
                      key={data?.id}
                      onContextMenu={(event) =>
                        handleContextMenu(event, data.id)
                      }
                      style={{
                        backgroundColor: getTableRowColor(data.id, rowId),
                      }}
                      sx={{
                        padding: "10px",
                        "& td": {
                          wordBreak: "break-word",
                        },
                      }}
                    >
                      <TableCell>{data?.name}</TableCell>
                      <TableCell>{data["CustomerName.0"]}</TableCell>
                      <TableCell>
                        {data?.subscription_leaf_location_limit}
                      </TableCell>
                      {/* <TableCell>{data["parameters.django.cuname"]}</TableCell> */}
                      <TableCell>{data["parameters.django.cuemail"]}</TableCell>
                      <TableCell>
                        <Tooltip
                          placement="top-end"
                          arrow
                          enterDelay={600}
                          title={
                            <p>
                              Click to visit NextRadea app. <br /> Right click
                              to copy NextRadea app url
                            </p>
                          }
                        >
                          <Button
                            onClick={() => handleRedirect(data.frontend_url)}
                            sx={{
                              padding: "0px !important",
                              textTransform: "none",
                              fontSize: "0.875rem !important",
                            }}
                          >
                            <Typography
                              sx={{ marginRight: "5px", fontSize: "inherit" }}
                            >
                              {data.frontend_url}
                            </Typography>
                            <OpenInNewIcon sx={{ fontSize: 18 }} />
                          </Button>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        sx={{
                          color:
                            calcLicenceExpiration(
                              data?.subscription_valid_until
                            ) ||
                              calcLicenceExpiration(
                                data?.subscription_valid_until
                              ) === 0
                              ? customTheme.palette.error.main
                              : "inherit",
                        }}
                      >
                        {data?.subscription_valid_until
                          ? format(
                            new Date(data?.subscription_valid_until),
                            "dd MMMM yyyy HH:mm"
                          )
                          : "Not Set"}
                      </TableCell>
                      <TableCell>
                        {data["subscription_metadata.contactPerson"]
                          ? data["subscription_metadata.contactPerson"]
                          : "Not Set"}
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={
                            data.is_launched ? "Activated" : "Not Activated"
                          }
                          color={data.is_launched ? "success" : "warning"}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={data?.status}
                          color={getStatusColors(data?.status)}
                          size="small"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledTable>
      <TablePagination
        rowsPerPageOptions={[20, 25, 50, 100]}
        component="div"
        count={Instance?.count || 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {anchorEl && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement="bottom-start"
          sx={{ padding: "10px 0px 8px 5px" }}
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClose}>
              <Fade {...TransitionProps}>
                <Paper>
                  <Styled>
                    <MenuItem
                      disabled={getLaunchStatus()}
                      onClick={() => handleLaunchInstance(rowId)}
                    >
                      <RocketLaunchIcon />
                      Launch
                    </MenuItem>
                    <MenuItem
                      disabled={getPauseStatus()}
                      onClick={() => handlePauseInstance(rowId)}
                    >
                      <PauseIcon />
                      Pause
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        navigate("/add-edit-instance", {
                          state: {
                            isInitialized: singleInstance?.is_launched,
                            id: editId,
                          },
                        })
                      }
                    >
                      <EditIcon />
                      Edit
                    </MenuItem>
                    <MenuItem
                      disabled={singleInstance?.status === "deleting"}
                      onClick={() => setConfirmationDialog(true)}
                    >
                      <DeleteIcon />
                      Delete
                    </MenuItem>
                    <MenuItem onClick={() => handleCopyUrl(rowId)}>
                      <ContentCopyIcon />
                      Copy Url
                    </MenuItem>
                    <MenuItem onClick={() => setShowDialog(true)}>
                      <ExtensionOutlinedIcon />
                      Versions and Extensions
                    </MenuItem>
                    <MenuItem onClick={() => setShowInstanceStatusDialog(true)}>
                      <AnalyticsIcon />
                      Show instance status
                    </MenuItem>
                    <MenuItem onClick={() => setShowDevices(true)}>
                      <SurroundSoundOutlinedIcon />
                      Check devices
                    </MenuItem>
                  </Styled>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      )}
      {confirmationDialog && rowId && (
        <ConfirmationDialog
          isOpen={confirmationDialog}
          onClose={handleDeleteDialog}
          onConfirm={() => handleDeleteInstance(rowId)}
          title="Delete Instance"
          confirmText="Confirm"
          isWarning
        >
          Are you sure you want to delete instance, It will delete all customer
          data ?
        </ConfirmationDialog>
      )}
      {showInstanceStatusDialog && (
        <InstanceStatusDialogue
          handleCloseDialog={handleInstanceStatusDialog}
          id={editId}
          isOpen={showInstanceStatusDialog}
        />
      )}
      {showFindDevice && (
        <FindDevice
          isOpen={showFindDevice}
          handleCloseDialog={handleFindDialog}
        />
      )}
      {showDialog && (
        <AddExtension
          isOpen={showDialog}
          handleCloseDialog={handleCloseDialog}
          id={editId}
        />
      )}
      {showDevices && (
        <Devices
          handleCloseDialog={handleDevices}
          id={editId}
          isOpen={showDevices}
        />
      )}
    </Box>
  );
}
