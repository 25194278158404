// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import { FieldArray, FormikProps, FormikProvider } from "formik";
import {
  FormControl,
  MenuItem,
  Select,
  Grid,
  Box,
  Button,
  SelectChangeEvent,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {} from "../../../utils/validations/validations";
import HelperText from "../../../components/HelperText/HelperText";
import Label from "../../../components/Label/Label";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import customTheme from "../../../theme/customTheme";
import { ExtensionApiResponse } from "../../../redux/api/api.types";
import { Extension, InitialValues } from "./integrationFormTypes";

export type ExtensionsType = {
  formik: FormikProps<InitialValues>;
  extensionData: ExtensionApiResponse | undefined;
  foundExtensionLength: number;
};

export default function Extensions({
  formik,
  extensionData,
  foundExtensionLength,
}: ExtensionsType) {
  const [showExtensionField, setShowExtensionField] = useState(false);
  const [selectedExtensions, setSelectedExtensions] = useState<string[]>([]); // State to track selected extensions

  // Processing for form
  const extensionLocal = extensionData?.results.filter(
    (item) => item.type === "vertical"
  );
  const extensionsNames = extensionLocal?.map(({ name }) => ({
    key: name,
    value: name,
  }));

  useEffect(() => {
    // Extract integration names from formik initial values and set them as selected integrations
    if (formik.values.extensions) {
      const initialExtensionNames = formik.values.extensions.map(
        (extension: Extension) => extension.extension_name
      );
      setSelectedExtensions(initialExtensionNames);
    }
  }, [formik.values.extensions]);

  // Function to handle extension name change
  const handleExtensionNameChange = (
    event: SelectChangeEvent<string>,
    idx: number
  ) => {
    const extensionName = event.target.value as string;
    setSelectedExtensions((prev) => {
      const newSelectedExtensions = [...prev];
      newSelectedExtensions[idx] = extensionName; // Update selected extension for the current field
      return newSelectedExtensions;
    });
  };

  useEffect(() => {
    if (foundExtensionLength > 0) {
      setShowExtensionField(true);
    }
  }, [foundExtensionLength]);

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name="extensions"
        render={(arrayHelpers) => (
          <>
            {formik.values.extensions.map((item, idx) => (
              <React.Fragment key={idx}>
                {showExtensionField && (
                  <Grid item xs={12} sx={{ pb: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Label
                          text="Extension Name"
                          error={
                            formik?.touched?.extensions?.[idx]
                              ?.extension_name &&
                            Boolean(
                              (
                                (formik?.errors as any)?.[idx] as {
                                  extension_name: string;
                                }
                              )?.extension_name
                            )
                          }
                        />
                        <FormControl fullWidth>
                          <Select
                            labelId="extension-id"
                            id="extension-id"
                            name={`extensions.${idx}.extension_name`}
                            value={item.extension_name}
                            onChange={(event) => {
                              formik.setFieldValue(
                                `extensions.${idx}.extension_name`,
                                event.target.value
                              );
                              handleExtensionNameChange(event, idx); // Handle extension name change
                            }}
                          >
                            {extensionsNames &&
                              extensionsNames.map((extension) => (
                                <MenuItem
                                  key={extension.key}
                                  value={extension.value}
                                >
                                  {extension.value}
                                </MenuItem>
                              ))}
                          </Select>
                          {(
                            (formik?.errors as any)?.[idx] as {
                              extension_name: string;
                            }
                          )?.extension_name && (
                            <HelperText
                              text={
                                (
                                  (formik?.errors as any)?.[idx] as {
                                    extension_name: string;
                                  }
                                )?.extension_name
                              }
                            />
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <Label
                          text="Version"
                          error={
                            formik?.touched?.extensions?.[idx]?.tag &&
                            Boolean(
                              (
                                formik?.errors?.extensions?.[idx] as {
                                  tag: string;
                                }
                              )?.tag
                            )
                          }
                        />
                        <Box display="flex">
                          <FormControl fullWidth>
                            <Select
                              labelId="extension-id"
                              id="extension-id"
                              name={`extensions.${idx}.tag`}
                              value={item.tag}
                              onChange={(event) =>
                                formik.setFieldValue(
                                  `extensions.${idx}.tag`,
                                  event.target.value
                                )
                              }
                            >
                              {(
                                (selectedExtensions[idx] &&
                                  extensionLocal?.find(
                                    (ext) =>
                                      ext.name === selectedExtensions[idx]
                                  )?.tags) ||
                                []
                              ).map((tag) => (
                                <MenuItem key={tag.name} value={tag.id}>
                                  {tag.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {(
                              formik?.errors?.extensions?.[idx] as {
                                tag: string;
                              }
                            )?.tag && (
                              <HelperText
                                text={
                                  (
                                    formik?.errors?.extensions?.[idx] as {
                                      tag: string;
                                    }
                                  )?.tag
                                }
                              />
                            )}
                          </FormControl>
                          <Box
                            onClick={() => arrayHelpers.remove(idx)}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mb: "1rem",
                              ml: "1rem",
                            }}
                            component="span"
                          >
                            <DeleteOutlineOutlinedIcon
                              sx={{
                                fontSize: "1.5rem",
                                color: customTheme.palette.error.main,
                                cursor: "pointer",
                              }}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Button
                type="button"
                // disabled={
                //   extensionLocal?.length === formik.values.extensions.length
                // }
                onClick={() => {
                  setShowExtensionField(true);
                  if (showExtensionField) {
                    arrayHelpers.push({
                      extension_name: "",
                      tag: "",
                    });
                    setSelectedExtensions((prev) => [...prev, ""]); // Initialize selected extension for the new field
                  }
                }}
              >
                + ADD EXTENSION
              </Button>
            </Grid>
          </>
        )}
      />
    </FormikProvider>
  );
}
