import * as React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import BasicTooltip from "../BasicTooltip/BasicTooltip";

type SectionheaderProps = Readonly<{
  title: string;
  tooltip?: string;
}>;
const WrapperBreadcrumbs = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  marginBottom: theme.spacing(4),
  borderBottom: "0.0625rem solid #aeaeae",
}));

export default function Sectionheader({ title, tooltip }: SectionheaderProps) {
  return (
    <WrapperBreadcrumbs>
      <Box sx={{ display: "inline-block", verticalAlign: "middle" }}>
        <Typography
          variant="h5"
          sx={{
            display: "inline-block",
          }}
        >
          {title}
        </Typography>
        {tooltip && (
          <Box sx={{ display: "inline-block", verticalAlign: "middle", ml: 1 }}>
            <BasicTooltip text={tooltip} />
          </Box>
        )}
      </Box>
    </WrapperBreadcrumbs>
  );
}
