import {
  ReaderAppDataType,
  ReaderAppType,
  ReaderSerialMapDetail,
} from "./api.types";
import appApi from "./appApi";

const readerAppApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    createReaderApp: build.mutation<ReaderAppType, Omit<ReaderAppType, "id">>({
      query: (readerAppData) => ({
        url: `/reader-gateway/reader-application/`,
        method: "POST",
        body: readerAppData,
      }),
      invalidatesTags: ["Readerapp"],
    }),
    getReaderApps: build.query<
      ReaderAppDataType,
      { debounceValue: string; rowsPerPage: number }
    >({
      query: ({ debounceValue, rowsPerPage }) =>
        `/reader-gateway/reader-application/?search=${debounceValue}&page_size=${rowsPerPage}`,
      providesTags: ["Readerapp"],
    }),
    getReaderAppById: build.query<ReaderAppType, string>({
      query: (readerAppId) =>
        `/reader-gateway/reader-application/${readerAppId}/`,
      providesTags: (result, error, id) => [{ type: "Readerapp", id }],
    }),
    deleteReaderApp: build.mutation<null, string>({
      query: (id) => ({
        url: `/reader-gateway/reader-application/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Readerapp"],
    }),
    getReaderAppPaginated: build.query<ReaderAppDataType, any>({
      query: ({ page, rowsPerPage }) =>
        `/reader-gateway/reader-application/?page=${page}&page_size=${rowsPerPage}`,
      providesTags: ["Readerapp"],
    }),
    getReaderSerialMap: build.query<ReaderSerialMapDetail, string>({
      query: (serial) =>
        `/reader-gateway/reader-serial/?serial_number=${serial}`,
      providesTags: ["Readerapp"],
    }),
    getReaderSerialMapDetail: build.query<ReaderSerialMapDetail, void>({
      query: () => `/reader-gateway/reader-serial/`,
      providesTags: ["Readerapp"],
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useCreateReaderAppMutation,
  useGetReaderAppsQuery,
  useDeleteReaderAppMutation,
  useGetReaderAppByIdQuery,
  useGetReaderAppPaginatedQuery,
  useLazyGetReaderSerialMapQuery,
  useGetReaderSerialMapDetailQuery,
} = readerAppApi;
