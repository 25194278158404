// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { FieldArray, FormikProps, FormikProvider } from "formik";
import HelperText from "../../../components/HelperText/HelperText";
import Label from "../../../components/Label/Label";
import customTheme from "../../../theme/customTheme";
import { InstanceType } from "../../../redux/api/api.types";
import StyledWrapperCard from "../../../styles/StyledWrapperCard";

type SalesContactsProps = {
  formik: FormikProps<InstanceType>;
};

export default function SalesContacts({ formik }: SalesContactsProps) {
  return (
    <FormikProvider value={formik}>
      <FieldArray
        name="subscription_metadata.sales_contacts"
        render={(arrayHelpers) => (
          <>
            {formik?.values?.subscription_metadata?.sales_contacts?.map(
              (contact, index) => (
                <StyledWrapperCard key={index}>
                  <Grid container spacing={2}>
                    <Grid item xs={11}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Label text="Sales first name" />
                          <TextField
                            fullWidth
                            id={`sales_contacts.${index}.sales_firstname`}
                            name={`subscription_metadata.sales_contacts.${index}.sales_firstname`}
                            value={contact.sales_firstname}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Label text="Sales last name" />
                          <TextField
                            fullWidth
                            id={`sales_contacts.${index}.sales_lastname`}
                            name={`subscription_metadata.sales_contacts.${index}.sales_lastname`}
                            value={contact.sales_lastname}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Label
                            text="Sales e-mail"
                            error={
                              formik?.touched?.subscription_metadata
                                ?.sales_contacts?.[index]?.sales_email &&
                              Boolean(
                                (
                                  formik?.errors?.subscription_metadata
                                    ?.sales_contacts?.[index] as {
                                    sales_email: string;
                                  }
                                )?.sales_email
                              )
                            }
                            isRequired
                          />
                          <TextField
                            fullWidth
                            id={`sales_contacts.${index}.sales_email`}
                            name={`subscription_metadata.sales_contacts.${index}.sales_email`}
                            value={contact.sales_email}
                            onChange={formik.handleChange}
                          />
                          {(
                            formik?.errors?.subscription_metadata
                              ?.sales_contacts?.[index] as {
                              sales_email: string;
                            }
                          )?.sales_email && (
                            <HelperText
                              text={
                                (
                                  formik?.errors?.subscription_metadata
                                    ?.sales_contacts?.[index] as {
                                    sales_email: string;
                                  }
                                )?.sales_email
                              }
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        pt: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {index !== 0 && (
                        <Box
                          onClick={() => arrayHelpers.remove(index)}
                          sx={{ textAlign: "center", alignSelf: "self-end" }}
                        >
                          <DeleteOutlineOutlinedIcon
                            sx={{
                              fontSize: "2rem",
                              color: customTheme.palette.error.main,
                              cursor: "pointer",
                            }}
                          />
                          <Typography
                            variant="body1"
                            sx={{
                              pt: 0.5,
                              color: customTheme.palette.error.main,
                              cursor: "pointer",
                            }}
                          >
                            Remove
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </StyledWrapperCard>
              )
            )}
            <Grid
              item
              xs={12}
              sx={{
                pb: 2,
                display: "inline-block",
                verticalAlign: "middle",
                ml: 2,
              }}
            >
              <Button
                type="button"
                onClick={() =>
                  arrayHelpers.push({
                    sales_email: "",
                    sales_firstname: "",
                    sales_lastname: "",
                  })
                }
              >
                + ADD ANOTHER SALES CONTACT
              </Button>
            </Grid>
          </>
        )}
      />
    </FormikProvider>
  );
}
