// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/destructuring-assignment */

type ErrorResponseType = {
  detail?: {
    non_field_errors?: string[];
    encodings?: Array<{
      metadata?: string[];
    }>;
    reader_configuration_ids?: string[];
  } | string;
};

function ErrorResponse(error: ErrorResponseType) {
  // Check for non_field_errors
  if (
    typeof error.detail === "object" &&
    error?.detail?.non_field_errors &&
    error?.detail?.non_field_errors?.length > 0
  ) {
    return error?.detail?.non_field_errors?.[0];
  }

  // Check if detail is a string
  if (typeof error?.detail === "string") {
    return error?.detail;
  }

  // Check for reader_configuration_ids errors
  if (
    typeof error.detail === "object" &&
    (error?.detail as { reader_configuration_ids: string[] })
      ?.reader_configuration_ids &&
    (error?.detail as { reader_configuration_ids: string[] })
      ?.reader_configuration_ids.length > 0
  ) {
    return (error?.detail as { reader_configuration_ids: string[] })
      ?.reader_configuration_ids[0];
  }

  // Check for encodings metadata errors
  if (
    typeof error.detail === "object" &&
    error?.detail?.encodings &&
    error?.detail?.encodings.length > 0
  ) {
    const encodingErrors = error.detail.encodings
      .map((encoding) => encoding.metadata?.[0])
      .filter(Boolean); // Filter out undefined values
    if (encodingErrors.length > 0) {
      return encodingErrors[0]; // Return the first metadata error found
    }
  }

  // Return null if no errors are found
  return null;
}

export default ErrorResponse;