import React, { useState } from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useGetInstanceByIdQuery } from "../../../redux/api/instanceApi";
import customTheme from "../../../theme/customTheme";
import CommonDialog from "../../../components/Dialog/CommonDialog";
import { useLazyGetReaderSerialMapQuery } from "../../../redux/api/readerAppApi";
import { useCreateErrorMessage } from "../../../hooks/useCreateErrorMessage";

type FindDeviceProps = {
  isOpen: boolean;
  handleCloseDialog: () => void;
};
const initialValues = {
  search: "",
};
export default function FindDevice({
  isOpen,
  handleCloseDialog,
}: FindDeviceProps) {
  const [instanceId, setInstanceId] = useState("");
  const [notFoundStatus, setNotFoundStatus] = useState(false);
  const [instanceStatus, setInstanceStatus] = useState(false);
  const [trigger] = useLazyGetReaderSerialMapQuery();
  const { createErrorMessage } = useCreateErrorMessage();
  const { enqueueSnackbar } = useSnackbar();
  const { data: Instance, isLoading } = useGetInstanceByIdQuery(instanceId, {
    refetchOnMountOrArgChange: true,
    skip: instanceId === "",
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      trigger(values.search)
        .unwrap()
        .then((payload) => {
          if (payload.results.length > 0) {
            enqueueSnackbar("Device found with the serial number", {
              variant: "success",
              autoHideDuration: 2000,
            });
            setInstanceId(payload.results[0].instance);
            setInstanceStatus(true);
            setNotFoundStatus(false);
            setSubmitting(false);
          } else {
            enqueueSnackbar(
              "Request was succesful, but no device found for this serial number.",
              {
                variant: "success",
                autoHideDuration: 2000,
              }
            );
            setInstanceStatus(false);
            setNotFoundStatus(true);
            setSubmitting(false);
          }
        })
        .catch((error) => {
          createErrorMessage({
            error,
            message: "Failed to find the device with the serial number",
          });
          setSubmitting(false);
        });
    },
  });
  return (
    <CommonDialog
      isOpen={isOpen}
      onClose={handleCloseDialog}
      title="Find Device"
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="search"
              name="search"
              label="Device Serial Number"
              value={formik.values.search}
              onChange={formik.handleChange}
            />
          </Grid>
          {instanceStatus ? (
            <Grid item xs={12}>
              <Typography>
                Device is connected to the instance
                <Typography
                  component="span"
                  sx={{
                    fontWeight: customTheme.typography.fontWeightBold,
                    ml: "0.2rem",
                  }}
                >
                  {Instance?.name}
                </Typography>
              </Typography>
            </Grid>
          ) : notFoundStatus ? (
            <Grid item xs={12}>
              <Typography>Device not found.</Typography>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={formik.isSubmitting}
                  size="large"
                >
                  {formik.isSubmitting || isLoading ? (
                    <CircularProgress
                      size="1.5rem"
                      sx={{
                        color: customTheme.palette.common.white,
                        marginRight: "0.5rem",
                      }}
                    />
                  ) : (
                    <MyLocationOutlinedIcon sx={{ marginRight: "0.5rem" }} />
                  )}
                  <Box
                    component="span"
                    sx={{
                      color:
                        formik.isSubmitting || isLoading
                          ? customTheme.palette.common.white
                          : customTheme.palette.common.white,
                    }}
                  >
                    Find Device
                  </Box>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={handleCloseDialog}
                  size="large"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </CommonDialog>
  );
}
