import appApi from "./appApi";
import {
  InstanceDataType,
  InstanceType,
  AddationalInstanceTypes,
} from "./api.types";

const instanceApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    createInstance: build.mutation<InstanceDataType, InstanceType>({
      query: (instanceData) => ({
        url: `/customer-instance/`,
        method: "POST",
        body: instanceData,
      }),
      invalidatesTags: ["Instance"],
    }),
    getInstance: build.query<
      InstanceDataType,
      { debounceValue: string; rowsPerPage: number | null }
    >({
      query: ({ debounceValue, rowsPerPage }) =>
        `/customer-instance/?search=${debounceValue}&page_size=${rowsPerPage}`,
      providesTags: ["Instance"],
    }),
    getInstanceById: build.query<
      InstanceType & AddationalInstanceTypes,
      string
    >({
      query: (instanceId) => `/customer-instance/${instanceId}`,
      providesTags: (result, error, id) => [{ type: "Instance", id }],
      keepUnusedDataFor: 5,
    }),
    updateRadeaInstance: build.mutation<InstanceDataType, string>({
      query: (instanceId) => ({
        url: `/customer-instance/${instanceId}/instance-status`,
        method: "GET",
      }),
      invalidatesTags: ["Instance"],
    }),
    editInstance: build.mutation<
      InstanceDataType,
      { id: string; patch: InstanceType }
    >({
      query: (instanceData) => ({
        url: `/customer-instance/${instanceData.id}/`,
        method: "PATCH",
        body: instanceData.patch,
      }),
      invalidatesTags: ["Instance"],
    }),
    deleteInstance: build.mutation<null, string>({
      query: (id) => ({
        url: `/customer-instance/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Instance"],
    }),
    launchInstance: build.mutation<null, string>({
      query: (id) => ({
        url: `/customer-instance/${id}/launch/`,
        method: "POST",
      }),
      invalidatesTags: ["Instance"],
    }),
    pauseInstance: build.mutation<null, string>({
      query: (id) => ({
        url: `/customer-instance/${id}/pause/`,
        method: "POST",
      }),
      invalidatesTags: ["Instance"],
    }),
    getInstancePaginated: build.query<InstanceDataType, any>({
      query: ({ page, rowsPerPage }) =>
        `/customer-instance/?page=${page}&page_size=${rowsPerPage}`,
      providesTags: ["Instance"],
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useCreateInstanceMutation,
  useGetInstanceQuery,
  useGetInstanceByIdQuery,
  useEditInstanceMutation,
  useDeleteInstanceMutation,
  useLaunchInstanceMutation,
  usePauseInstanceMutation,
  useGetInstancePaginatedQuery,
  useUpdateRadeaInstanceMutation,
} = instanceApi;
