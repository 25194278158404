// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import appApi from "./appApi";
import { ExtensionApiResponse } from "./api.types";

const extensionApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getExtensionImages: build.query<ExtensionApiResponse, void>({
      query: () => "deployment/images",
      providesTags: ["Extensions"],
    }),
    assignExtension: build.mutation<
      void,
      { dataToSubmit: { tag_ids: (number | string | undefined)[] }; id: string }
    >({
      query: ({ id, ...data }) => ({
        url: `/customer-instance/${id}/tags/`,
        method: "PUT",
        body: data.dataToSubmit,
      }),
      invalidatesTags: ["Extensions"],
    }),
    getExtensionInInstanceUpdate: build.query<void, { id: string }>({
      query: ({ id }) => ({
        url: `/customer-instance/${id}/update/`,
        method: "POST",
        body: { instance_id: id },
      }),
      providesTags: ["Extensions"],
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useGetExtensionImagesQuery,
  useAssignExtensionMutation,
  useLazyGetExtensionInInstanceUpdateQuery,
} = extensionApi;
