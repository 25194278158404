import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

export type PollingState = {
  label: string;
  value: number;
};
let initialState = { label: "Off", value: 0 } as PollingState;

const serializedState = localStorage.getItem("state");
if (serializedState) {
  initialState = JSON.parse(serializedState);
}

export const pollingSlice = createSlice({
  name: "pollingSlice",
  initialState,
  reducers: {
    setPollingInterval: (
      state,
      {
        payload: { label, value },
      }: PayloadAction<{ label: string; value: number }>
    ) => {
      // eslint-disable-next-line no-unused-expressions, no-param-reassign
      state.label = label;
      // eslint-disable-next-line no-param-reassign
      state.value = value;
    },
  },
});

export const { setPollingInterval } = pollingSlice.actions;

export const selectPollingInterval = (state: RootState) => state.polling;
export default pollingSlice.reducer;
