import React from "react";
import Button from "@mui/material/Button";
import { DialogActions } from "@mui/material";
import customTheme from "../../../theme/customTheme";

export type ActionDialogProps = Readonly<{
  onClose: () => void;
  confirmText?: string;
  closeText?: string;
}>;
export default function ActionDialog({
  confirmText = "Save",
  closeText = "Cancel",
  onClose,
}: ActionDialogProps) {
  return (
    <DialogActions
      sx={{
        paddingTop: "2rem",
        borderTop: "0.0625rem solid #8A8A8A",
        paddingLeft: "0rem",
        paddingRight: "0rem",
        justifyContent: "flex-start",
        width: "100%",
        marginTop: "0.625rem",
      }}
    >
      <Button
        variant="contained"
        sx={{
          backgroundColor: customTheme.palette.primary.main,
          color: "#fff",
        }}
        type="submit"
      >
        {confirmText}
      </Button>
      <Button
        variant="outlined"
        sx={{
          backgroundColor: "#fff",
          color: customTheme.palette.primary.main,
        }}
        onClick={onClose}
      >
        {closeText}
      </Button>
    </DialogActions>
  );
}
