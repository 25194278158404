import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { logout } from "../userSlice";
import { setTokens } from "../tokenSlice";
import type { RootState } from "../store";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl:
    window.location.host.substring(0, window.location.host.indexOf(":")) ===
    "localhost"
      ? "http://localhost:8001"
      : `${`${window.location.protocol}//${window.location.host
          .substring(0, window.location.host.indexOf("."))
          .concat("api")
          .concat(
            window.location.host
              .split(":")[0]
              .substring(window.location.host.indexOf("."))
          )}`}`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).tokens.accessToken;
    if (token && endpoint !== "userLogin" && endpoint !== "userAuthToken") {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const customFetchBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await baseQuery(
          {
            url: "auth/token/refresh/",
            method: "POST",
            body: {
              refresh: (api.getState() as RootState).tokens.refreshToken,
            },
          },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          // Retry the initial query
          //set new access token in local storage
          api.dispatch(
            setTokens({
              accessToken: (refreshResult as any).data.access,
              refreshToken: (api.getState() as RootState).tokens.refreshToken,
            })
          );
          // localStorage.setItem("accessToken", refreshResult.data.access);
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export default customFetchBaseQuery;
