// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/no-array-index-key */
import * as React from "react";
import { NavLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { styled } from "@mui/material/styles";
import customTheme from "../../theme/customTheme";

type BreadcrumbsContent = Readonly<{
  title?: string;
  link?: string;
}>;
type BasicBreadcrumbsProps = Readonly<{
  breadcrumbs: ReadonlyArray<BreadcrumbsContent>;
}>;
const WrapperBreadcrumbs = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  marginBottom: theme.spacing(4),
  borderBottom: "0.0625rem solid #aeaeae",
}));

export default function BasicBreadcrumbs({
  breadcrumbs,
}: BasicBreadcrumbsProps) {
  return (
    <WrapperBreadcrumbs>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbs.map((item, index) =>
          item.link ? (
            <NavLink
              to={item.link}
              style={{ textDecoration: "none" }}
              key={item.title}
            >
              <Typography
                color={customTheme.palette.primary.main}
                sx={{ fontSize: "1.125rem", fontWeight: "400" }}
                key={index}
              >
                {item.title}
              </Typography>
            </NavLink>
          ) : (
            <Typography
              color={customTheme.palette.text.primary}
              sx={{ fontSize: "1.125rem", fontWeight: "400" }}
              key={index}
            >
              {item.title}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </WrapperBreadcrumbs>
  );
}
