import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ViewRouter from "./views/ViewRouter";

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={{ flex: 1, display: "flex" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <ViewRouter />
        </div>
      </div>
    </LocalizationProvider>
  );
}
