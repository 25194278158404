import { styled } from "@mui/material";

export default styled("div")(({ theme }) => ({
  "& .MuiTableContainer-root": {
    "& .MuiTableCell-root": {
      borderBottom: "0.0625rem solid #DBDBDB",
    },
    "& .MuiTableBody-root .MuiTableRow-root.selectedRow:hover": {
      cursor: "default",
    },
    "& .MuiTableBody-root .MuiTableRow-root.selectedRow:hover .MuiTableCell-root":
      {
        color: theme.palette.text.primary,
      },
    "& .MuiTableBody-root .MuiTableRow-root:hover": {
      cursor: "pointer",
      backgroundColor: "#e4f1fc !important",
    },
    "& .MuiTableBody-root .MuiTableRow-root.noData:hover": {
      cursor: "pointer",
      backgroundColor: "transparent !important",
    },
    "& .MuiTableBody-root .MuiTableRow-root:hover .MuiTableCell-root": {
      color: "#4e99d8",
    },
  },
}));
