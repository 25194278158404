import React from "react";
import { NavLink } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import { CardActionArea, Grid } from "@mui/material";
import StyledCardSetting from "../../styles/customSettingCard";

export default function AdvancedTools() {
  const advancedToolsCard = [
    {
      urlLink: "/reader-type",
      textLink: "Reader Type",
      icon: <SettingsInputAntennaIcon />,
    },
    {
      urlLink: "/reader-app",
      textLink: "Reader App",
      icon: <AppRegistrationIcon />,
    },
  ];

  return (
    <StyledCardSetting>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {advancedToolsCard.map((card) => (
          <Grid item xs={2} sm={4} md={4} key={card.textLink}>
            <Card>
              <NavLink to={card.urlLink} end>
                <CardActionArea>
                  <CardContent>
                    {card.icon}
                    <Typography gutterBottom variant="h5" component="div">
                      {card.textLink}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </NavLink>
            </Card>
          </Grid>
        ))}
      </Grid>
    </StyledCardSetting>
  );
}
