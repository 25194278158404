// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-plusplus */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable consistent-return */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import * as Yup from "yup";

const loginValidationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

const tokenValidationSchema = Yup.object().shape({
  secret_phrase: Yup.number()
    .min(6, "Number is Too Short!")
    .required("Number is Required!"),
});

const instanceName = /^[a-zA-Z0-9\- ]+$/;
const subDomain = /^[a-z0-9-]*$/;
const cuname = /^[a-z][a-z0-9_@.]+$/;

const cuNameText =
  "Use only lowercase letters (a-z), '@', '_', '.', and digits (0-9) and should start with a-z.";

const instanceValidationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(instanceName, "Invalid Name")
    .required("Instance name is Required!"),
  customer: Yup.string().required("Sales org is Required!"),
  subdomain: Yup.string()
    .matches(
      subDomain,
      "Invalid Subdomain, Use only lowercase letters (a-z), '_', and digits (0-9)"
    )
    .max(15, "Ensure this field has no more than 15 characters.")
    .required("Subdomain is Required!"),
  parameters: Yup.object().shape({
    django: Yup.object().shape({
      cuemail: Yup.string()
        .email("Invalid email format")
        .required("E-Mail is required"),
      cuname: Yup.string()
        .matches(/^(?!admin\b)/i, "The username should NOT be admin or Admin")
        .matches(cuname, cuNameText)
        .required("Username is required!"),
      cufirstname: Yup.string().required("Firstname is required!"),
      culastname: Yup.string().required("Lastname is required!"),
      culanguage: Yup.string().required("Language is required!"),
    }),
  }),
  encodings: Yup.array()
    .min(1, "At least one encoding method is required")
    .of(
      Yup.object().shape({
        method: Yup.string().required("Method is required"),
        default: Yup.boolean(),
        metadata: Yup.object().when("method", {
          is: (method: string) => method === "SGTIN96",
          then: Yup.object().shape({
            partition: Yup.string().required("Company code is required."),
            companyPrefix: Yup.string().required("Company prefix is required."),
          }),
          otherwise: Yup.object().shape({
            partition: Yup.string().notRequired(),
            companyPrefix: Yup.string().notRequired(),
          }),
        }),
      })
    )// Custom test at the array level to check if any 'default' is true
    .test('has-default', 'At least one encoding must be selected as default', (encodings) =>
      // Check if any encoding has 'default' set to true
      encodings.some((encoding) => encoding.default === true)
    ),
  subscription_reader_limit: Yup.number()
    .typeError("Only numbers are allowed!")
    .moreThan(0, "Value must be greater than 0")
    .required("Reader limit is required!"),
  subscription_leaf_location_limit: Yup.number()
    .typeError("Only numbers are allowed!")
    .moreThan(0, "Value must be greater than 0")
    .required("Location limit is required!"),
  subscription_metadata: Yup.object().shape({
    contactPerson: Yup.string()
      .email("Invalid email format")
      .required("Instance technical contact e-mail is required"),
    company_name: Yup.string().required("Customer company name is Required!"),
    sales_contacts: Yup.array().of(
      Yup.object().shape({
        sales_email: Yup.string()
          .email("Invalid email format")
          .required("Sales e-mail is required!"),
      })
    ),
    resellers: Yup.array().of(
      Yup.object().shape({
        rs_email: Yup.string().email("Invalid email format").notRequired(),
      })
    ),
  }),
});



const version = /^[\d]+\.[\d]+\.[\d]+$/;

const readerAppValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  version: Yup.string()
    .matches(version, "Should be correct format e.g 1.0.0")
    .required("Version is required"),
  download_url: Yup.string().required("Download url is required"),
  type: Yup.string().required("Type is required"),
  schema: Yup.string().required("Configuration Schema is Required"),
});

const usernameMatch = /^[a-z][a-z0-9_@.]+$/;
const userValidationSchema = Yup.object().shape({
  username: Yup.string()
    .matches(usernameMatch, cuNameText)
    .required(cuNameText)
    .max(150),
  email: Yup.string()
    .email("Invalid email format")
    .required("E-Mail is required"),
  first_name: Yup.string().required("First name is required!"),
  last_name: Yup.string().required("Last name is required!"),
  roles: Yup.array().min(1, "At least one role is required"),
});

const passwordMatcher =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/;

const changePasswordValidationSchema = Yup.object().shape({
  old_password: Yup.string()
    .required("Password is required")
    .min(10, "Old password must be at least 10 characters."),
  new_password: Yup.string()
    .required("New Password is required")
    .matches(
      passwordMatcher,
      "Please check the below condition and try again."
    ),
  confirm_password: Yup.string()
    .equals(
      [Yup.ref("new_password")],
      "Confirm password must match new password. "
    )
    .required("Password confirmation is required"),
});

const passwordResetValidationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required!"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required!"),
});

const readerTypeValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  family: Yup.string().required("Reader family is Required"),
  ui_configuration: Yup.object().when("name", {
    is: (name: string) => name === "FR22-EXT-BFA",
    then: Yup.object().shape({
      antenna: Yup.string().required("Antenna is Required"),
      bfa: Yup.string().when("antenna", {
        is: (antenna: string) => Number(antenna) === 4,
        then: Yup.string().required(
          "BFA must be selected if 4 antenna is selected for FR22-EXT-BFA"
        ),
      }),
    }),
    otherwise: Yup.object().shape({
      antenna: Yup.string().required("Antenna is Required"),
    }),
  }),
});

//for unique field

Yup.addMethod(Yup.array, "uniqueIntegration", function (message) {
  return this.test("unique", message, (list) => {
    const arrayOfObjects = list?.map((item, index) => ({
      integration_name: list?.[index].integration_name,
    }));
    const uniquePairs = new Set(
      arrayOfObjects?.map((obj) => JSON.stringify(obj))
    );
    // Convert the unique pairs back to objects
    const uniqueObjects = Array.from(uniquePairs).map((pair) =>
      JSON.parse(pair)
    );
    const checkUnique = uniqueObjects.length === arrayOfObjects?.length;
    if (checkUnique) {
      return true;
    }
    const seenObjects = new Set();
    // Find the first non-unique index
    let firstNonUniqueIndex = -1;
    for (let index = 0; index < arrayOfObjects?.length; index++) {
      const obj = arrayOfObjects?.[index];
      const objString = JSON.stringify(obj);
      if (seenObjects.has(objString)) {
        firstNonUniqueIndex = index;
        break;
      }
      seenObjects.add(objString);
    }
    return new Yup.ValidationError(
      message,
      null,
      `[${firstNonUniqueIndex}].integration_name`
    );
  });
});

Yup.addMethod(Yup.array, "uniqueExtension", function (message) {
  return this.test("unique", message, (list) => {
    const arrayOfObjects = list?.map((item, index) => ({
      extension_name: list?.[index].extension_name,
    }));
    const uniquePairs = new Set(
      arrayOfObjects?.map((obj) => JSON.stringify(obj))
    );
    // Convert the unique pairs back to objects
    const uniqueObjects = Array.from(uniquePairs).map((pair) =>
      JSON.parse(pair)
    );
    const checkUnique = uniqueObjects.length === arrayOfObjects?.length;
    if (checkUnique) {
      return true;
    }
    const seenObjects = new Set();
    // Find the first non-unique index
    let firstNonUniqueIndex = -1;
    for (let index = 0; index < arrayOfObjects?.length; index++) {
      const obj = arrayOfObjects?.[index];
      const objString = JSON.stringify(obj);
      if (seenObjects.has(objString)) {
        firstNonUniqueIndex = index;
        break;
      }
      seenObjects.add(objString);
    }
    return new Yup.ValidationError(
      message,
      null,
      `[${firstNonUniqueIndex}].extension_name`
    );
  });
});

const extensionValidationSchema = Yup.object().shape({
  integrations: Yup.array()
    .of(
      Yup.object().shape({
        integration_name: Yup.string()
          .nullable()
          .transform((v, o) => (o === "" ? null : v)),
        tag: Yup.string()
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .when("integration_name", (integration_name: unknown) => {
            if (integration_name !== undefined) {
              return Yup.string().required("Version is required.");
            }
          }),
      })
    )
    .uniqueIntegration("Duplicate integration can not be selected."),
  extensions: Yup.array()
    .of(
      Yup.object().shape({
        extension_name: Yup.string()
          .nullable()
          .transform((v, o) => (o === "" ? null : v)),
        tag: Yup.string()
          .nullable()
          .transform((v, o) => (o === "" ? null : v))
          .when("extension_name", (extension_name: unknown) => {
            if (extension_name !== undefined) {
              return Yup.string().required("Version is required.");
            }
          }),
      })
    )
    .uniqueExtension("Duplicate extension can not be selected."),
});

export {
  instanceValidationSchema,
  loginValidationSchema,
  tokenValidationSchema,
  readerAppValidationSchema,
  userValidationSchema,
  changePasswordValidationSchema,
  passwordResetValidationSchema,
  readerTypeValidationSchema,
  extensionValidationSchema,
};
