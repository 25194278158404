import { styled } from "@mui/material";

export default styled("ul")(({ theme }) => ({
  borderRadius: 6,
  marginTop: theme.spacing(1),
  paddingLeft: 0,
  minWidth: 180,
  color:
    theme.palette.mode === "light"
      ? "rgb(55, 65, 81)"
      : theme.palette.grey[300],
  boxShadow:
    "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  "& .MuiMenu-list": {
    padding: "4px 0",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 18,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1.5),
    verticalAlign: "middle",
  },
}));
