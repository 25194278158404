import React from "react";
import Typography from "@mui/material/Typography";
import customTheme from "../../theme/customTheme";

type LabelProps = {
  text: string;
  error?: boolean;
  isRequired?: boolean;
};
export default function Label({ text, error, isRequired = false }: LabelProps) {
  return (
    <Typography
      variant="body2"
      sx={{ pb: "0.625rem", display: "inline-block" }}
    >
      {text}
      {isRequired && (
        <span
          style={{
            marginLeft: "0.2rem",
            color: error
              ? customTheme.palette.error.main
              : customTheme.palette.text.secondary,
          }}
        >
          *
        </span>
      )}
    </Typography>
  );
}
