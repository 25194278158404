import appApi from "./appApi";
import { BackendVersion } from "./api.types";

const systemApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getBackendVersion: build.query<BackendVersion, void>({
      query: () => "version/",
      providesTags: ["Versions"],
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetBackendVersionQuery } = systemApi;
