import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

type SearchType = {
  name: string;
  handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function Search({ name, handleSearch }: SearchType) {
  return (
    <TextField
      id="outlined-name"
      label="Search"
      value={name}
      onChange={handleSearch}
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
