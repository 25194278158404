import * as React from "react";
import { styled } from "@mui/material/styles";
import Badge, { BadgeProps } from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import customTheme from "../../theme/customTheme";

type StatusIndicatorTypes = {
  label: string;
};

interface StyledBadgeProps extends BadgeProps {
  label?: string;
}

const StyledBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== "label",
})<StyledBadgeProps>(({ label, theme }) => ({
  ...(label && {
    "& .MuiBadge-badge": {
      backgroundColor: `${
        label === "Off"
          ? `${customTheme.palette.error.main}`
          : customTheme.palette.success.main
      }`,
      color: `${
        label === "Off"
          ? `${customTheme.palette.error.main}`
          : customTheme.palette.success.main
      }`,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: `${
          label === "Off" ? "none" : "ripple 1.2s infinite ease-in-out"
        }`,
        border: `${label === "Off" ? "none" : "1px solid currentColor"}`,
        content: '""',
      },
    },
  }),
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function StatusIndicator({ label }: StatusIndicatorTypes) {
  return (
    <Stack direction="row" spacing={2}>
      <StyledBadge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        variant="dot"
        label={label}
      />
    </Stack>
  );
}
