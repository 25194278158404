import React, { ReactChild } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import WarningIcon from "@mui/icons-material/Warning";
import { DialogActions, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import customTheme from "../../../theme/customTheme";

const WrapperDialog = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  minWidth: "32rem",
  backgroundColor: customTheme.palette.white.light,
  "& svg.iconMaterialui": {
    color: customTheme.palette.error.main,
  },
  "& .MuiDialogContent-root": {
    paddingTop: "1.25rem !important",
  },
  "& .MuiFormControl-root": {
    width: "100%",
    marginBottom: "1rem",
    "&:last-child": {
      marginBottom: "0",
    },
  },
}));
export type ConfirmationDialogProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmText?: string;
  closeText?: string;
  title: string;
  children: ReactChild;
  isWarning?: boolean;
  icon?: React.ReactElement;
  buttonType?: boolean;
  disableUpdateState?: boolean;
}>;

export default function ConfirmationDialog({
  confirmText,
  closeText,
  title,
  children,
  isOpen,
  isWarning,
  onClose,
  onConfirm,
  icon,
  buttonType = false,
  disableUpdateState = false,
}: ConfirmationDialogProps) {
  const confirmButtonText = confirmText || "Save";
  const closeButtonText = closeText || "Cancel";

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <WrapperDialog>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            m: 0,
            padding: "0.625rem 1.25rem 0rem 1.25rem",
          }}
        >
          {isWarning && (
            <span
              style={{
                position: "relative",
                marginRight: "0.5rem",
                top: "0.3rem",
              }}
            >
              <WarningIcon className="iconMaterialui" />
            </span>
          )}
          {icon && (
            <span
              style={{
                position: "relative",
                marginRight: "0.5rem",
                top: "0.3rem",
              }}
            >
              {icon}
            </span>
          )}
          {title}
        </DialogTitle>
        <DialogContent sx={{ fontFamily: "Roboto" }}>{children}</DialogContent>
        <DialogActions
          sx={{
            paddingLeft: "1.5rem",
            paddingRight: "1.5rem",
            marginTop: "0.5rem",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: customTheme.palette.primary.main,
              color: "#fff",
            }}
            onClick={onConfirm}
            type={buttonType ? "submit" : "button"}
            disabled={disableUpdateState}
          >
            {confirmButtonText}
          </Button>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#fff",
              color: customTheme.palette.primary.main,
            }}
            onClick={onClose}
          >
            {closeButtonText}
          </Button>
        </DialogActions>
      </WrapperDialog>
    </Dialog>
  );
}
