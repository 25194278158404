/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

type SettingState = {
  dense: boolean;
};

const initialState: SettingState = {
  dense: true,
};

export const settingSlice = createSlice({
  initialState,
  name: "settingSlice",
  reducers: {
    setTableSize: (
      state,
      action: PayloadAction<{
        dense: boolean;
      }>
    ) => {
      state.dense = action.payload.dense;
    },
  },
});

export const { setTableSize } = settingSlice.actions;
export const setTableSeting = (state: RootState) => state.settings;

export default settingSlice.reducer;
