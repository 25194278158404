import React from "react";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  OutlinedInput,
  Box,
} from "@mui/material";
import {
  selectPollingInterval,
  setPollingInterval,
} from "../../redux/pollingSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import StatusIndicator from "../StatusIndicator/StatusIndicator";

export type PollingIntervalTypes = Readonly<{
  options: { label: string; value: number }[];
  title?: string;
}>;

export default function PollingInterval({
  options,
  title = "Update Interval",
}: PollingIntervalTypes) {
  const dispatch = useAppDispatch();
  const { value, label } = useAppSelector(selectPollingInterval);
  const handlePolling = (event: SelectChangeEvent) => {
    let selectedInterval = { label, value };
    options.forEach((interval) => {
      if (interval.value === Number(event.target.value)) {
        selectedInterval = {
          label: interval.label,
          value: Number(event.target.value),
        };
      }
    });
    dispatch(setPollingInterval(selectedInterval));
  };
  return (
    <Box sx={{ width: "10rem" }}>
      <FormControl fullWidth>
        <InputLabel id="polling-select-label">{title}</InputLabel>
        <Select
          labelId="polling-select-label"
          id="polling-select"
          input={<OutlinedInput label={title} />}
          value={value?.toString()}
          label={label}
          onChange={handlePolling}
          size="small"
          renderValue={() => (
            <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
              <StatusIndicator label={label} />
              {label}
            </Box>
          )}
        >
          {options.map((interval) => (
            <MenuItem key={interval.label} value={interval.value}>
              {interval.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
