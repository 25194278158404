import React from "react";
import { NavLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import customTheme from "../../../theme/customTheme";

type NavLinkItemProps = {
  keyItem: string | number;
  url: string;
  text: string;
  icon: any;
  iconRight?: any;
  marginLeft?: string;
  onClick?: () => void;
  isSubMenu?: boolean;
};
const StyledListItem = styled("div")(() => ({
  "& a .MuiListItemText-root span": {
    color: "#FCFCFC",
  },
  "& a .MuiListItemIcon-root svg": {
    color: "#FCFCFC",
  },
  "& a.active .MuiListItemText-root span": {
    color: customTheme.palette.primary.main,
  },
  "& a.active .MuiListItemIcon-root svg": {
    color: customTheme.palette.primary.main,
  },
  "& .MuiListItemButton-root": {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  "& .MuiListItemText-root span": {
    fontSize: "1rem",
    fontWeight: 400,
  },
}));
function NavLinkItem({
  keyItem,
  url,
  text,
  icon,
  iconRight,
  marginLeft,
  onClick,
  isSubMenu,
}: NavLinkItemProps) {
  return (
    <StyledListItem>
      <ListItem
        key={keyItem}
        disablePadding
        sx={(theme) => ({
          "& .MuiTouchRipple-child": {
            backgroundColor: `${theme.palette.primary.main} !important`,
          },
        })}
      >
        <NavLink
          className={({ isActive }) => (isActive ? "active" : "inactive")}
          to={url}
        >
          <ListItemButton onClick={onClick}>
            <ListItemIcon style={{ marginLeft }}>{icon}</ListItemIcon>
            <ListItemText primary={text} />
            {isSubMenu && iconRight}
          </ListItemButton>
        </NavLink>
      </ListItem>
    </StyledListItem>
  );
}

export default NavLinkItem;
