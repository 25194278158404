import { useEffect, useState } from "react";

export default function useDebounce<T>(value: T, timeout: number): T {
  const [debounceValue, setdebounceVale] = useState(value);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setdebounceVale(value);
    }, timeout);
    return () => {
      clearTimeout(timerId);
    };
  }, [value, timeout]);

  return debounceValue;
}
