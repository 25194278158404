import appApi from "./appApi";
import { CustomerDataType } from "./api.types";

const customerApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    createCustomer: build.mutation<
      CustomerDataType,
      { name: string; metadata: object }
    >({
      query: (customerData) => ({
        url: `/customer/`,
        method: "POST",
        body: customerData,
      }),
      invalidatesTags: ["Customers"],
    }),
    // getCustomers: build.query<CustomerDataType, void>({
    //   query: () => "/customer/",
    //   providesTags: ["Customers"],
    // }),
    getCustomers: build.query<
      CustomerDataType,
      { debounceValue: string; rowsPerPage: number | null }
    >({
      query: ({ debounceValue, rowsPerPage }) =>
        `/customer/?search=${debounceValue}&page_size=${rowsPerPage}`,
      providesTags: ["Customers"],
    }),
    getCustomersPaginated: build.query<CustomerDataType, any>({
      query: ({ page, rowsPerPage }) =>
        `/customer/?page=${page}&page_size=${rowsPerPage}`,
      providesTags: ["Customers"],
    }),
    getCustomerById: build.query<
      { name: string; metadata: { description: string } },
      string
    >({
      query: (customerId) => `/customer/${customerId}`,
      providesTags: (result, error, id) => [{ type: "Customers", id }],
    }),
    editCustomer: build.mutation<
      CustomerDataType,
      { id: string; patch: { name: string; metadata: object } }
    >({
      query: (customerData) => ({
        url: `/customer/${customerData.id}/`,
        method: "PATCH",
        body: customerData.patch,
      }),
      invalidatesTags: ["Customers"],
    }),
    deleteCustomer: build.mutation<null, string>({
      query: (id) => ({
        url: `/customer/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Customers"],
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useCreateCustomerMutation,
  useGetCustomersQuery,
  useGetCustomerByIdQuery,
  useEditCustomerMutation,
  useDeleteCustomerMutation,
  useGetCustomersPaginatedQuery,
} = customerApi;
