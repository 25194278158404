import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../components/Loading";

export default function RedirectPage() {
  const location = useLocation();
  useEffect(() => {
    window.location.replace(location?.state?.frontend_url);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        rowGap: 8,
        marginTop: "5rem",
      }}
    >
      <Typography sx={{ display: "flex", justifyContent: "center" }}>
        Redirecting Please Wait ....
      </Typography>
      <Loading />
    </Box>
  );
}
