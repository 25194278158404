import React from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useSnackbar } from "notistack";
import customTheme from "../../theme/customTheme";
import { useUserLoginMutation } from "../../redux/api/userApi";
import { loginValidationSchema } from "../../utils/validations/validations";
import { UIButtonVariants } from "../../utils/enum/enum";
import { useCreateErrorMessage } from "../../hooks/useCreateErrorMessage";

function Login() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [userLogin] = useUserLoginMutation();
  const { createErrorMessage } = useCreateErrorMessage();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: (values, { resetForm }) => {
      userLogin(values)
        .unwrap()
        .then((payload) => {
          if (payload) {
            enqueueSnackbar("Success", {
              variant: "success",
              autoHideDuration: 2000,
            });
            resetForm();
            navigate("/verification", {
              state: {
                verification_token: payload.verificator_token,
              },
            });
          }
        })
        .catch((error) => {
          createErrorMessage({
            error,
            message: "Failed to sign in.",
          });
          resetForm();
        });
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "#012169" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box sx={{ mt: 1 }}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id="username"
              name="username"
              label="Username"
              sx={{ width: "100%", minWidth: "100%", marginBottom: "0.5rem" }}
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
            <TextField
              fullWidth
              id="password"
              name="password"
              type="password"
              label="Password"
              sx={{ width: "100%", minWidth: "100%", marginBottom: "0.5rem" }}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: customTheme.palette.primary.main,
                color: "#fff",
                mt: 3,
                mb: 2,
              }}
              type="submit"
              disabled={formik.isSubmitting || !formik.dirty}
              startIcon={
                formik.isSubmitting ? (
                  <CircularProgress size="1rem" />
                ) : undefined
              }
            >
              Sign In
            </Button>
          </form>
        </Box>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Button
            onClick={() => {
              navigate("/password-reset");
            }}
            variant={UIButtonVariants.UNDERLINED}
          >
            Forgot Password ?
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Login;
