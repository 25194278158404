// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import { createSlice } from "@reduxjs/toolkit";

const initialState = 0 as number;

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increment: (state) =>
      //as initial state is primitive, must return state (e.g. the old way)
      state + 1,
    decrement: (state) => state - 1,
    reset: () => 0,
  },
});
