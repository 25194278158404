// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/no-array-index-key */
import React from "react";
import { FormikProps } from "formik";
import { FormControl, MenuItem, Select, Grid, Box } from "@mui/material";
import {} from "../../../utils/validations/validations";
import HelperText from "../../../components/HelperText/HelperText";
import Label from "../../../components/Label/Label";
import BasicTooltip from "../../../components/BasicTooltip/BasicTooltip";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { ExtensionApiResponse } from "../../../redux/api/api.types";
import { InitialValues } from "./integrationFormTypes";
import { validateVersion } from "../../../utils/functions/functions";
import { ApplicationTypes, HelperTextVariant } from "../../../utils/enum/enum";

export type ExtensionsType = {
  formik: FormikProps<InitialValues>;
  extensionData: ExtensionApiResponse | undefined;
  deployedTagName: string | undefined;
};

export default function FrontendApplication({
  formik,
  extensionData,
  deployedTagName,
}: ExtensionsType) {
  // Processing for form
  const extensionLocal = extensionData?.results.filter(
    (item) => item.name === ApplicationTypes.FRONTEND
  );
  const extensionsNames = extensionLocal?.map(({ name }) => ({
    key: name,
    value: name,
  }));

  return (
    <Grid item xs={12} sx={{ pb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Label
            text="Frontend Application Name"
            error={
              formik?.touched?.frontendApplication?.frontendApplication_name &&
              Boolean(
                (
                  formik?.errors.frontendApplication as any as {
                    frontendApplication_name: string;
                  }
                )?.frontendApplication_name
              )
            }
          />
          <FormControl fullWidth>
            <Select
              labelId="frontendApplication-id"
              id="frontendApplication-id"
              name="frontendApplication.frontendApplication_name"
              value={formik.values.frontendApplication.frontendApplication_name}
              onChange={(event) => {
                formik.setFieldValue(
                  `frontendApplication.frontendApplication_name`,
                  event.target.value
                );
                // handleExtensionNameChange(event, idx); // Handle extension name change
              }}
            >
              {extensionsNames &&
                extensionsNames.map((extension) => (
                  <MenuItem key={extension.key} value={extension.value}>
                    {extension.value}
                  </MenuItem>
                ))}
            </Select>
            {(
              formik?.errors.frontendApplication as any as {
                frontendApplication_name: string;
              }
            )?.frontendApplication_name && (
              <HelperText
                text={
                  (
                    formik?.errors.frontendApplication as any as {
                      frontendApplication_name: string;
                    }
                  )?.frontendApplication_name
                }
              />
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Label text="Version" />
          <Box sx={{ display: "inline-block", verticalAlign: "middle", ml: 1 }}>
            <BasicTooltip text="If no version is selected for first time instance launch, latest version will be deployed automatically" />
          </Box>
          <Box display="flex">
            <FormControl fullWidth>
              <Select
                labelId="frontendApplication-id"
                id="frontendApplication-id"
                name="frontendApplication.tag"
                value={formik.values.frontendApplication.tag}
                onChange={(event) => {
                  const selectedTag = extensionData?.results
                    .find((item) => item.name === ApplicationTypes.FRONTEND)
                    ?.tags.find((tag) => tag.id === event.target.value);

                  formik.setFieldValue(
                    `frontendApplication.tag`,
                    event.target.value
                  );
                  formik.setFieldValue(
                    `frontendApplication.name`,
                    selectedTag ? selectedTag.name : ""
                  );
                }}
              >
                {(
                  extensionData?.results.find(
                    (item) => item.name === ApplicationTypes.FRONTEND
                  )?.tags || []
                ).map((tag) => (
                  <MenuItem key={tag.name} value={tag.id}>
                    {tag.name}
                  </MenuItem>
                ))}
              </Select>
              <HelperText
                text={validateVersion(
                  deployedTagName,
                  formik.values.frontendApplication.name
                )}
                variant={HelperTextVariant.OUTLINED}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
