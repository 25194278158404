import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import OutputOutlinedIcon from "@mui/icons-material/OutputOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hooks/storeHooks";
import { setUserAuth, logout } from "../../redux/userSlice";

function Header() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, user } = useAppSelector(setUserAuth);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(logout());
    handleClose();
  };

  const handleNavigatePasswordChange = () => {
    navigate("/change-password");
    handleClose();
  };

  const handleNavigate = () => {
    navigate("/user-profile");
    handleClose();
  };
  return (
    <header
      style={{
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" noWrap component="div" sx={{ color: "black" }}>
          Admin Tool
        </Typography>
        {isLoggedIn ? (
          <div
            style={{
              display: "flex",
              flex: isLoggedIn ? 1 : 0,
              justifyContent: "flex-end",
            }}
          >
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ textTransform: "none" }}
              // sx={{ backgroundColor: "rgb(1 33 105 / 4%)" }}
            >
              Welcome {user?.username}
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleNavigate}>
                <AccountCircleIcon sx={{ fill: "grey", mr: "10px" }} />
                <Typography>Profile</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleNavigatePasswordChange()}>
                <ModeEditIcon sx={{ fill: "grey", mr: "10px" }} />
                <Typography>Change Password</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <OutputOutlinedIcon sx={{ fill: "grey", mr: "10px" }} /> Logout
              </MenuItem>
            </Menu>
          </div>
        ) : null}
      </div>
    </header>
  );
}

export default Header;
