import React, { ReactChild, ReactNode } from "react";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import {
  UIComponentColorVariants,
  UIComponentVarinats,
} from "../../utils/enum/enum";

type CustomAlertType = {
  title?: string;
  children: ReactChild;
  variant?: UIComponentVarinats;
  severity?: UIComponentColorVariants;
  startIcon?: ReactNode;
  actionIcon?: ReactNode | string;
  handleClose?: () => void;
  open?: boolean;
  actions?: boolean;
};

export default function CustomAlert({
  title = "",
  children,
  variant,
  severity,
  startIcon,
  handleClose,
  open = true,
  actionIcon = <CloseIcon fontSize="inherit" />,
  actions,
}: CustomAlertType) {
  return (
    <Collapse in={open}>
      <Alert
        icon={startIcon}
        severity={severity}
        variant={variant}
        action={
          actions ? (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
            >
              {actionIcon || <CloseIcon fontSize="inherit" />}
            </IconButton>
          ) : null
        }
        sx={{ mb: 2 }}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {children}
      </Alert>
    </Collapse>
  );
}
