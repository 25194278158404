import React from "react";

import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useGetReaderSerialsQuery } from "../../../redux/api/readerTypeApis";
import CommonDialog from "../../../components/Dialog/CommonDialog";

type InstanceStatusDialogueType = {
  isOpen: boolean;
  handleCloseDialog: () => void;
  id: string;
};
const StyledWrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
export default function Devices({
  isOpen,
  handleCloseDialog,
  id,
}: InstanceStatusDialogueType) {
  const { data: readerSerials } = useGetReaderSerialsQuery(id);
  const handleCopy = (serial: string) => {
    navigator.clipboard.writeText(serial);
  };
  return (
    <CommonDialog isOpen={isOpen} onClose={handleCloseDialog} title="Devices">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h5">
            Devices serial numbers
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6">
            Total Devices count: {readerSerials?.results?.length}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledWrapper>
            <List>
              {readerSerials &&
                readerSerials?.results.map((serials) => (
                  <ListItem
                    key={serials?.serial_number}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="copy"
                        onClick={() => handleCopy(serials?.serial_number)}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText primary={serials?.serial_number} />
                  </ListItem>
                ))}
            </List>
          </StyledWrapper>
        </Grid>
        <Grid item xs={12}>
          {readerSerials && readerSerials?.results?.length <= 0 && (
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6">
              No Devices for this Instance.
            </Typography>
          )}
        </Grid>
      </Grid>
    </CommonDialog>
  );
}
