import React from "react";
import { useFormik } from "formik";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useSnackbar } from "notistack";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import {
  styled,
  Grid,
  Avatar,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import customTheme from "../../theme/customTheme";
import CustomAlert from "../../components/CustomAlert/CustomAlert";
import { useForgetPasswordMutation } from "../../redux/api/userApi";
import {
  UIComponentColorVariants,
  UIButtonVariants,
} from "../../utils/enum/enum";
import { passwordResetValidationSchema } from "../../utils/validations/validations";
import { useCreateErrorMessage } from "../../hooks/useCreateErrorMessage";

const ResetWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(4),
  "& .MuiFormControl-root": {
    width: "100%",
    marginBottom: "1rem",
    "&:last-child": {
      marginBottom: "0",
    },
  },
}));

const FormTitle = styled("div")(({ theme }) => ({
  height: "2rem",
  marginBottom: theme.spacing(2),
}));

function PasswordReset() {
  const [forgetPassword, { isLoading }] = useForgetPasswordMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { createErrorMessage } = useCreateErrorMessage();

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
    },
    validationSchema: passwordResetValidationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      forgetPassword(values)
        .unwrap()
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((_) => {
          enqueueSnackbar(
            "A password reset link has been sent to your email.",
            {
              variant: "success",
              autoHideDuration: 10000,
              preventDuplicate: true,
            }
          );
          resetForm();
          setSubmitting(false);
        })
        .catch((error) => {
          createErrorMessage({
            error,
            message: "Failed to reset the password.",
          });
        });
      navigate("/login");
    },
  });

  return (
    <Paper elevation={3} sx={{ width: 500, margin: "50px auto auto auto" }}>
      <ResetWrapper>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mb: "1rem" }}
        >
          <Grid item>
            <Avatar sx={{ m: 1, bgcolor: "#012169" }}>
              <LockOutlinedIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <FormTitle>
              <Typography variant="h5">Forgot Password</Typography>
            </FormTitle>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              <CustomAlert
                title="info"
                severity={UIComponentColorVariants.INFO}
              >
                Please enter your username and email to reset password or
                contact support.
              </CustomAlert>
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sx={{ pb: 2 }}>
              <TextField
                fullWidth
                id="username"
                name="username"
                label="Username"
                sx={{ width: "100%", minWidth: "100%", marginBottom: "0.5rem" }}
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={
                  formik?.touched?.username && formik?.errors?.username
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ pb: 2 }}>
              <TextField
                fullWidth
                id="email"
                name="email"
                type="email"
                label="Email"
                sx={{ width: "100%", minWidth: "100%", marginBottom: "0.5rem" }}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik?.touched?.email && formik?.errors?.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            size="large"
            fullWidth
            sx={{
              backgroundColor: customTheme.palette.primary.main,
              color: "#fff",
              mt: "1rem",
            }}
            type="submit"
            disabled={formik.isSubmitting || !formik.dirty}
            startIcon={
              formik.isSubmitting || isLoading ? (
                <CircularProgress size="1rem" />
              ) : undefined
            }
          >
            Reset Password
          </Button>
        </form>
      </ResetWrapper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            variant={UIButtonVariants.UNDERLINED}
          >
            Go back to Login
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PasswordReset;
