// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import { addDays, differenceInDays } from "date-fns";
import { ExtensionNamesTypes, ExtensionValuesTypes } from "../enum/enum";
import { Extension } from "../../views/InstanceManagement/AddExtension/integrationFormTypes";

export function calcLicenceExpiration(subExpiry: Date | undefined) {
  const daysUntillExp = addDays(new Date(), 30);

  if (subExpiry) {
    const daysUntillExpToTime = new Date(daysUntillExp).getTime();
    const expirationDateToTime = new Date(subExpiry).getTime();

    if (expirationDateToTime <= daysUntillExpToTime) {
      return differenceInDays(new Date(subExpiry), new Date());
    }
  }
  return null;
}

export const isOnProduction = () => process.env?.ON_PRODUCTION === "yes";

export function validateVersion(deployedTagName: string | undefined, formikAppTagName: string) {
  // Helper function to parse version strings like "v2.4.9" into [2, 4, 9]
  // Remove leading 'v' if present, split into parts, and convert each part to a number
  const parseVersion = (version: string) =>
    version
      .replace(/^v/, "")
      .split(".")
      .map(Number);

  if (!deployedTagName) return "";

  const [deployedMajor, deployedMinor, deployedPatch] = parseVersion(deployedTagName);
  const [formikMajor, formikMinor, formikPatch] = parseVersion(formikAppTagName);

  if (
    deployedMajor > formikMajor ||
    (deployedMajor === formikMajor && deployedMinor > formikMinor) ||
    (deployedMajor === formikMajor && deployedMinor === formikMinor && deployedPatch > formikPatch)
  ) {
    return "Warning! Downgrading to lower version is dangerous.";
  }

  return "";
}

//extracting names so that we can add to subscription_metadata
export function extractExtensionNames(extensions: Extension[], tagLength: number) {
  if (tagLength === 0) {
    return []
  }
  const extensionNames: string[] = []
  const extensionNamesData = extensions
    .filter((item) => item.extension_name !== "")
    .map((extension) => extension.extension_name);
  if (extensionNamesData.includes(ExtensionNamesTypes.RADEA_IO_SENSOR_DATA)) {
    extensionNames.push(ExtensionValuesTypes.SENSOR)
  }
  //more if statements can be added here in the futire for other types of extensions
  return extensionNames
}
