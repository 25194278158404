import { combineReducers } from "@reduxjs/toolkit";
import { userSlice } from "./userSlice";
import appApi from "./api/appApi";
import { counterSlice } from "./counterSlice";
import { tokenSlice } from "./tokenSlice";
import { settingSlice } from "./settingSlice";
import { pollingSlice } from "./pollingSlice";

const reducer = combineReducers({
  counter: counterSlice.reducer,
  user: userSlice.reducer,
  tokens: tokenSlice.reducer,
  settings: settingSlice.reducer,
  polling: pollingSlice.reducer,
  [appApi.reducerPath]: appApi.reducer,
});

export type ReducerType = ReturnType<typeof reducer>;
export default reducer;
