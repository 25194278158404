import React from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Button,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import ActionDialog from "../../../components/Dialog/ActionDialog";
import customTheme from "../../../theme/customTheme";
import CommonDialog from "../../../components/Dialog/CommonDialog";
import { readerAppValidationSchema } from "../../../utils/validations/validations";

import {
  useCreateReaderAppMutation,
  useGetReaderAppByIdQuery,
} from "../../../redux/api/readerAppApi";
import { useCreateErrorMessage } from "../../../hooks/useCreateErrorMessage";

type AddEditReaderAppProps = {
  id?: string;
  isOpen: boolean;
  isEdit: boolean;
  preview: boolean;
  handleCloseDialog: () => void;
};
const initialValues = {
  name: "",
  version: "",
  download_url: "",
  type: "",
  schema: "",
};

export default function AddEditReaderApp({
  id,
  isOpen,
  handleCloseDialog,
  isEdit,
  preview,
}: AddEditReaderAppProps) {
  const [createReaderApp] = useCreateReaderAppMutation();
  const { data: singleReaderApp } = useGetReaderAppByIdQuery(id);

  const { createErrorMessage } = useCreateErrorMessage();

  if ((id && singleReaderApp) || (isEdit && singleReaderApp)) {
    initialValues.name = singleReaderApp?.name;
    initialValues.version = singleReaderApp?.version;
    initialValues.download_url = singleReaderApp?.download_url;
    initialValues.type = singleReaderApp?.type;
    initialValues.schema = JSON.stringify(singleReaderApp?.schema, null, 2);
  } else {
    initialValues.name = "";
    initialValues.version = "";
    initialValues.download_url = "";
    initialValues.type = "";
    initialValues.schema = "";
  }
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: readerAppValidationSchema,
    onSubmit: (values) => {
      createReaderApp({ ...values, schema: JSON.parse(values.schema) })
        .unwrap()
        .then((payload) => {
          if (payload) {
            enqueueSnackbar("success", {
              variant: "success",
              autoHideDuration: 2000,
            });
            handleCloseDialog();
          }
          navigate("/reader-app");
        })
        .catch((error) => {
          if (isEdit) {
            createErrorMessage({
              error,
              message: "Failed to update a reader app.",
            });
          } else {
            createErrorMessage({
              error,
              message: "Failed to create a new reader app.",
            });
          }

          handleCloseDialog();
        });
    },
  });
  function validateVersion() {
    const validateCode = singleReaderApp?.version?.localeCompare(
      formik.values.version,
      "en",
      {
        sensitivity: "base",
      }
    );

    if (validateCode === 1) {
      return "New Version number should be higher than the current one ";
    }
    if (validateCode === 0) {
      return "You need to increasse the current version if you want to update.";
    }
    return "";
  }

  validateVersion();
  return (
    <CommonDialog
      isOpen={isOpen}
      onClose={handleCloseDialog}
      title={
        id && !preview
          ? "Edit Reader App Information"
          : preview
          ? "Reader App Information"
          : "Add Reader App"
      }
    >
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          disabled={isEdit}
          id="name"
          name="name"
          label="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          fullWidth
          id="version"
          name="version"
          label="Version"
          value={formik.values.version}
          onChange={formik.handleChange}
          error={formik.touched.version && Boolean(formik.errors.version)}
          helperText={formik.touched.version && formik.errors.version}
        />
        {isEdit && (
          <FormHelperText
            sx={{ mb: "10px", color: "darkred" }}
            id="version-helper-text"
          >
            {validateVersion()}
          </FormHelperText>
        )}
        <TextField
          fullWidth
          disabled={isEdit}
          id="download_url"
          name="download_url"
          label="Download URL"
          value={formik.values.download_url}
          onChange={formik.handleChange}
          error={
            formik.touched.download_url && Boolean(formik.errors.download_url)
          }
          helperText={formik.touched.download_url && formik.errors.download_url}
        />
        <FormControl fullWidth>
          <InputLabel id="type-label">Type</InputLabel>
          <Select
            disabled={isEdit}
            labelId="demo-simple-select-label"
            id="type"
            name="type"
            value={formik.values.type}
            label="Age"
            onChange={formik.handleChange}
            error={formik.touched.type && Boolean(formik.errors.type)}
          >
            <MenuItem value="ap">Application</MenuItem>
            <MenuItem value="fw">Firmware</MenuItem>
            <MenuItem value="pi">Plugin</MenuItem>
          </Select>
          <FormHelperText>
            {formik.touched.type && formik.errors.type}
          </FormHelperText>
        </FormControl>
        <TextField
          id="schema"
          name="schema"
          value={formik.values.schema}
          onChange={formik.handleChange}
          multiline
          rows={10}
          variant="outlined"
          label="Schema"
          error={formik.touched.schema && Boolean(formik.errors.schema)}
          helperText={formik.touched.schema && formik.errors.schema}
        />
        {id && !isEdit ? (
          <DialogActions
            sx={{
              paddingTop: "1rem",
              borderTop: "0.0625rem solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#fff",
                color: customTheme.palette.primary.main,
              }}
              onClick={handleCloseDialog}
            >
              Close
            </Button>
          </DialogActions>
        ) : (
          <ActionDialog onClose={handleCloseDialog} />
        )}
      </form>
    </CommonDialog>
  );
}
