import React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { styled, Grid, Avatar } from "@mui/material";
import CustomAlert from "../../components/CustomAlert/CustomAlert";
import { useGetPasswordResetInfoByIdQuery } from "../../redux/api/userApi";
import { UIComponentColorVariants } from "../../utils/enum/enum";

const ResetWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(4),
  "& .MuiFormControl-root": {
    width: "100%",
    marginBottom: "1rem",
    "&:last-child": {
      marginBottom: "0",
    },
  },
}));

function PasswordResetView() {
  const { uuid } = useParams<{ uuid: string }>();
  const { data: ResetInfo } = useGetPasswordResetInfoByIdQuery(uuid, {
    refetchOnMountOrArgChange: true,
  });
  const navigate = useNavigate();

  return (
    <Paper elevation={3} sx={{ width: 500, margin: "100px auto auto auto" }}>
      <ResetWrapper>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{ mb: "1rem" }}
        >
          <Grid item>
            <Avatar sx={{ m: 1, bgcolor: "#012169" }}>
              <LockOutlinedIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              <CustomAlert
                title="Success"
                severity={UIComponentColorVariants.SUCCESS}
              >
                {ResetInfo?.detail
                  ? ResetInfo?.detail
                  : "If you have not received password in your email, please try again!"}
              </CustomAlert>
            </Typography>
          </Grid>
        </Grid>
      </ResetWrapper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Button
            id="basic-button"
            aria-haspopup="true"
            onClick={() => {
              navigate("/login");
            }}
            sx={{ textTransform: "none", mb: "1rem" }}
          >
            Go back to Login
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PasswordResetView;
