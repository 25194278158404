// eslint-disable-next-line import/prefer-default-export
import { createTheme } from "@mui/material/styles";
import { UIButtonVariants } from "../utils/enum/enum";
import {
  PALATTE_PRIMARY_MAIN_COLOR,
  PALATTE_SECONDARY_MAIN_COLOR,
  PALATTE_ERROR_MAIN_COLOR,
  TEXT_PRIMARY_COLOR,
  DISABLED_COLOR,
  TEXT_SECONDARY_COLOR,
  PALATTE_WARNING_MAIN_COLOR,
} from "../utils/constants/index";

// Augment the palette to include a white color
declare module "@mui/material/styles" {
  interface Palette {
    white: Palette["primary"];
  }

  interface PaletteOptions {
    white?: PaletteOptions["primary"];
  }
}

// Update the Button's color options to include a white option
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    underlined: true;
    white: true;
  }
}
const theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});

export default createTheme(theme, {
  palette: {
    primary: {
      light: "#6477A2",
      main: PALATTE_PRIMARY_MAIN_COLOR,
      dark: "#1565c0",
    },
    white: theme.palette.augmentColor({
      color: {
        light: "#FCFCFC",
        main: "#FFFFFF",
      },
      name: "white",
    }),
    secondary: {
      main: PALATTE_SECONDARY_MAIN_COLOR,
      light: "#FFFFFF",
    },
    warning: {
      main: "#ed6c02",
      light: "#FF8400",
      dark: "#e65100",
    },
    error: {
      main: PALATTE_ERROR_MAIN_COLOR,
      light: "#ef5350",
      dark: "#c62828",
    },
    info: {
      main: "#6CACE4",
    },
    success: {
      main: "#2F7C31",
    },
    // disabled color for theme
    action: {
      disabled: "#c8c8c8",
    },
    text: {
      primary: TEXT_PRIMARY_COLOR,
      secondary: TEXT_SECONDARY_COLOR,
    },
    background: {
      paper: "#F7F7F7",
    },
    divider: "#DBDBDB",
  },
  components: {
    MuiDivider: {
      variants: [
        {
          props: { variant: "dynamic" },
          style: {
            ":before": {
              borderTop: "thin solid #DBDBDB",
            },
            ":after": {
              borderTop: "thin solid #DBDBDB",
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            fontWeight: "500",
            fontSize: "0.875rem",
            backgroundColor: "#324C86)",
            "&:hover": {
              backgroundColor: "#324C86",
              boxShadow:
                "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            fontSize: "0.875rem",
            "&:hover": {
              backgroundColor: "#DCE9F4",
            },
          },
        },
        {
          props: { variant: UIButtonVariants.UNDERLINED },
          style: {
            textTransform: "none",
            textDecoration: "none",
            fontSize: "0.875rem",
            color: PALATTE_PRIMARY_MAIN_COLOR,
            "&:hover": {
              backgroundColor: "transparent",
              textDecoration: "underline",
            },
          },
        },
        {
          props: { color: "warning", variant: "contained" },
          style: {
            backgroundColor: PALATTE_ERROR_MAIN_COLOR,
          },
        },
        {
          props: { color: "warning", variant: "outlined" },
          style: {
            backgroundColor: "transparent",
            color: PALATTE_ERROR_MAIN_COLOR,
            border: `0.0625rem solid ${PALATTE_ERROR_MAIN_COLOR}`,
            "&:hover": {
              border: `0.0625rem solid ${PALATTE_ERROR_MAIN_COLOR}`,
              backgroundColor: "#F0D1D0",
            },
          },
        },
      ],
    },
    MuiButtonGroup: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            "& :not(:last-of-type)": {
              borderRadius: "0.25rem !important",
            },
            "& :not(:first-of-type)": {
              borderRadius: "0.25rem !important",
            },
          },
        },
      ],
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            ".Mui-disabled": {
              backgroundColor: `${DISABLED_COLOR} !important`,
            },
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            ".Mui-disabled": {
              backgroundColor: `${DISABLED_COLOR} !important`,
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "h5" },
          style: {
            color: TEXT_PRIMARY_COLOR,
            fontSize: "1.125rem",
            fontWeight: 500,
          },
        },
        {
          props: { variant: "h6" },
          style: {
            color: TEXT_PRIMARY_COLOR,
            fontSize: "0.875rem",
            lineHeight: "1.5rem",
          },
        },
        {
          props: { variant: "body1" },
          style: {
            color: TEXT_PRIMARY_COLOR,
            fontSize: "1rem",
          },
        },
        {
          props: { variant: "body2" },
          style: {
            color: TEXT_SECONDARY_COLOR,
            fontSize: "1rem",
          },
        },
      ],
    },
    MuiFormHelperText: {
      variants: [
        {
          props: { variant: "standard" },
          style: {
            color: PALATTE_ERROR_MAIN_COLOR,
            fontSize: "0.875rem",
            marginTop: "0.625rem",
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            fontFamily: "Roboto",
            color: PALATTE_WARNING_MAIN_COLOR,
            fontSize: "0.875rem",
            marginTop: "0.625rem",
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          position: "relative",
          "& .MuiTooltip-arrow": {
            "&::before": {
              backgroundColor: DISABLED_COLOR,
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.activeTab": {
            color: PALATTE_PRIMARY_MAIN_COLOR,
            borderBottom: `0.1875rem solid ${PALATTE_PRIMARY_MAIN_COLOR}`,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boderBottom: "0.0625rem solid #DBDBDB",
          "& .MuiAccordionSummary-root": {
            backgroundColor: "#F7F7F7",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            padding: "0.1875rem",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-colorInfo": {
            backgroundColor: "#6CACE4",
          },
          "&.MuiChip-colorWarning": {
            backgroundColor: "#FF8400",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#FCFCFC",
        },
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: "info" },
          style: {
            backgroundColor: "#FFFF",
            color: "#011136",
            border: "0.0625rem solid #6CACE4",
            "& .MuiAlert-icon": {
              color: "#6CACE4",
            },
          },
        },
      ],
    },
  },
});
