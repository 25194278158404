import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import { UIComponentColorVariants } from "../../utils/enum/enum";
import { getErrorMessage } from "./errorTypeVariants";

// This hook gives createSnackbarMessage function which creates error message with report button.
// It uses notistack: https://notistack.com/

type ErrorMessageArgs = {
  error: any;
  message?: string;
};

export default function useCreateErrorMessage() {
  const { enqueueSnackbar } = useSnackbar();

  function createErrorMessage({ error, message }: ErrorMessageArgs) {
    const errorMessage = message
      ? `${message} ${getErrorMessage(error)}`
      : getErrorMessage(error);

    enqueueSnackbar(errorMessage, {
      variant: UIComponentColorVariants.ERROR,
      key: `${uuidv4()}-error-snackbar`,
      preventDuplicate: true,
      autoHideDuration: 10000,
    });
  }

  return { createErrorMessage };
}
