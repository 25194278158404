import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import { useAppSelector } from "../../hooks/storeHooks";
import { setUserAuth } from "../../redux/userSlice";

export default function UserProfile() {
  const { user } = useAppSelector(setUserAuth);
  return (
    <Box sx={{ padding: "0 2.4rem", maxWidth: 1280, margin: "auto" }}>
      <Box
        sx={{
          display: "flex",
          alignItem: "center",
          marginBottom: "2.4rem",
          textOverflow: "ellipsis",
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", textOverflow: "ellipsis" }}
        >
          User Profile
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          rowGap: "2.4rem",
          columnGap: "2.4rem",
          // gridTemplateColumns: "9fr 4fr",
        }}
      >
        <Paper sx={{ boxShadow: "none" }}>
          <Card sx={{ display: "flex", alignItems: "center" }}>
            <CardContent>
              <Typography
                sx={{ fontSize: "1.2rem", fontWeight: 700 }}
                color="text.secondary"
                gutterBottom
              >
                Logged In User Information
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ padding: "2.4rem 3.2rem" }}>
            <CardContent>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "120px 1fr",
                  columnGap: "3.2rem",
                }}
              >
                <Box>
                  {" "}
                  <Avatar
                    sx={{
                      bgcolor: deepPurple[500],
                      height: "120px",
                      width: "120px",
                    }}
                  >
                    {user?.username.charAt(0).toUpperCase()}
                  </Avatar>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    // gridTemplateColumns: "1fr 1fr",
                    gridTemplateColumns: "repeat(auto-fit, minmax(15rem, 1fr))",
                    columnGap: "0.6rem",
                    rowGap: "0.6rem",
                  }}
                >
                  <TextField
                    variant="filled"
                    size="small"
                    value={user?.username}
                    label="Username"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    variant="filled"
                    size="small"
                    value={user?.email}
                    label="Email"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    variant="filled"
                    size="small"
                    value={user?.first_name}
                    label="First Name"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    variant="filled"
                    size="small"
                    value={user?.last_name}
                    label="Last Name"
                    InputProps={{ readOnly: true }}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Paper>
      </Box>
    </Box>
  );
}
