import { DevicesSerialNumbers, ReaderType, ReaderTypeList } from "./api.types";
import appApi from "./appApi";

const readerTypeApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    createReaderType: build.mutation<ReaderType, Omit<ReaderType, "id">>({
      query: (readerTypeData) => ({
        url: `/reader-gateway/reader-type/`,
        method: "POST",
        body: readerTypeData,
      }),
      invalidatesTags: ["ReaderType"],
    }),
    getReaderTypes: build.query<ReaderTypeList, void>({
      query: () => `/reader-gateway/reader-type/`,
      providesTags: ["ReaderType"],
    }),
    getReaderTypeById: build.query<ReaderType, string>({
      query: (readerTypeId) => `/reader-gateway/reader-type/${readerTypeId}/`,
      providesTags: (result, error, id) => [{ type: "ReaderType", id }],
    }),
    deleteReaderType: build.mutation<null, string>({
      query: (id) => ({
        url: `/reader-gateway/reader-type/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ReaderType"],
    }),
    updateReaderType: build.mutation<
      ReaderType,
      { id: string; patch: Omit<ReaderType, "id"> }
    >({
      query: (readerTypeEdit) => ({
        url: `/reader-gateway/reader-type/${readerTypeEdit.id}/`,
        method: "PATCH",
        body: readerTypeEdit.patch,
      }),
      invalidatesTags: ["ReaderType"],
    }),
    getReaderSerials: build.query<DevicesSerialNumbers, string>({
      query: (instanceId) =>
        `/reader-gateway/reader-serial/?instance=${instanceId}`,
      providesTags: ["ReaderType"],
    }),
  }),
});

// eslint-disable-next-line import/prefer-default-export
export const {
  useCreateReaderTypeMutation,
  useDeleteReaderTypeMutation,
  useGetReaderTypeByIdQuery,
  useGetReaderTypesQuery,
  useUpdateReaderTypeMutation,
  useGetReaderSerialsQuery,
} = readerTypeApi;
