import React, { ReactNode } from "react";
import { SnackbarKey, SnackbarProvider, useSnackbar } from "notistack";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import customTheme from "./theme/customTheme";

function DismissAction({ id }: { id: SnackbarKey | undefined }) {
  const { closeSnackbar } = useSnackbar();
  return (
    <Button onClick={() => closeSnackbar(id)}>
      <CloseIcon sx={{ color: customTheme.palette.secondary.light }} />
    </Button>
  );
}

const dismissCallback = (key) => <DismissAction id={key} />;

type SnackbarWrapperProps = {
  children: ReactNode;
};

function SnackbarWrapper({ children }: SnackbarWrapperProps) {
  return (
    <SnackbarProvider
      style={{ zIndex: 9999999 }}
      maxSnack={3}
      action={dismissCallback}
    >
      {children}
    </SnackbarProvider>
  );
}

export default SnackbarWrapper;
