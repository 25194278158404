// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Popper,
  Paper,
  ClickAwayListener,
  Fade,
  TableSortLabel,
  Button,
  Box,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import usePagination from "../../hooks/usePagination";
import {
  useDeleteCustomerMutation,
  useGetCustomersPaginatedQuery,
  useGetCustomersQuery,
} from "../../redux/api/customerApi";
import customTheme from "../../theme/customTheme";
import StyledTable from "../../theme/CustomTable/CustomTable";
import Styled from "../../theme/CustomMenu/customMenu";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import AddEditCustomer from "./AddEditCustomer/AddEditCustomer";
import { useAppSelector } from "../../hooks/storeHooks";
import { setTableSeting } from "../../redux/settingSlice";
import useDebounce from "../../hooks/useDebounce";
import Search from "../../components/Search";
import { useGetInstanceQuery } from "../../redux/api/instanceApi";

enum SortingDirectionEnum {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

type SortingDirection = `${SortingDirectionEnum}`;

type HeadCell = {
  id: string;
  label: string;
  isSorting: boolean;
};

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    label: "Customer",
    isSorting: true,
  },

  {
    id: "description",
    label: "Description",
    isSorting: false,
  },
];

export default function CustomerManagement() {
  // for pagination
  const {
    rowsPerPage,
    page,
    setPage,
    handleChangeRowsPerPage,
    handleChangePage,
  } = usePagination();
  const { dense } = useAppSelector(setTableSeting);
  const [name, setName] = React.useState("");
  const debounceValue = useDebounce(name, 700);
  const [deleteCustomer] = useDeleteCustomerMutation();
  const { data: Instances } = useGetInstanceQuery(
    { debounceValue: "", rowsPerPage: null },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [order, setOrder] = useState<SortingDirection>(
    SortingDirectionEnum.DESCENDING
  );
  const { data: Customers } =
    page > 1
      ? useGetCustomersPaginatedQuery({ page, rowsPerPage })
      : useGetCustomersQuery(
        { debounceValue, rowsPerPage },
        { refetchOnMountOrArgChange: true }
      );
  const [orderBy, setOrderBy] = useState("name");
  const [showDialog, setShowDialog] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [rowId, setRowId] = useState("");

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | any>(null);
  const { enqueueSnackbar } = useSnackbar();

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order: SortingDirection, orderBy: string) {
    return order === SortingDirectionEnum.ASCENDING
      ? (a: Record<string, unknown>, b: Record<string, unknown>) =>
        descendingComparator(a, b, orderBy)
      : (a: Record<string, unknown>, b: Record<string, unknown>) =>
        -descendingComparator(a, b, orderBy);
  }

  const handleCloseDialog = () => {
    setShowDialog(false);
    setRowId("");
    setOpen(false);
  };

  const handleDeleteDialog = () => {
    setConfirmationDialog(false);
  };

  const handleDeleteCustomer = () => {
    deleteCustomer(rowId)
      .unwrap()
      .then(() =>
        enqueueSnackbar("Success", {
          variant: "success",
          autoHideDuration: 2000,
        })
      )
      .catch(() =>
        enqueueSnackbar("Failed", {
          variant: "error",
          autoHideDuration: 2000,
        })
      );
    setConfirmationDialog(false);
    setRowId("");
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContextMenu = (e: React.MouseEvent, rowId: string) => {
    e.preventDefault();
    e.stopPropagation();
    const { clientX, clientY } = e;
    setOpen(true);
    setRowId(rowId);
    const virtualElement = {
      getBoundingClientRect: () => ({
        width: 0,
        height: 0,
        top: clientY,
        right: clientX,
        bottom: clientY,
        left: clientX,
      }),
    };
    setAnchorEl(virtualElement);
  };
  const id = open ? "instance-managment-menu" : undefined;

  // get background color of table row
  function getTableRowColor(
    rowId: string | number,
    selectedRowId: string | number | null
  ) {
    const highlightColor = customTheme.palette.secondary.main;
    const defaultColor = customTheme.palette.secondary.light;
    if (selectedRowId) {
      return selectedRowId === rowId ? highlightColor : defaultColor;
    }
    return defaultColor;
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setPage(1);
  };

  function isCustomerDisable() {
    return Instances?.results.some((instance) => instance.customer === rowId);
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="contained" onClick={() => setShowDialog(true)}>
          <AddCircleIcon sx={{ marginRight: "0.5rem" }} /> Create Customer
        </Button>
        <Search name={name} handleSearch={handleSearch} />
      </Box>
      <StyledTable>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            sx={dense ? { marginTop: "1rem" } : null}
          >
            <TableHead>
              <TableRow>
                {headCells.map((item) => (
                  <TableCell key={item.id}>
                    {item.isSorting ? (
                      <TableSortLabel
                        active={orderBy === `${item.id}`}
                        direction={orderBy === `${item.id}` ? order : "asc"}
                        onClick={createSortHandler(item.id)}
                      >
                        {item.label}
                      </TableSortLabel>
                    ) : (
                      item.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Customers?.results &&
                Customers?.results
                  ?.slice(rowsPerPage * 0, rowsPerPage * (page + 1))
                  ?.sort(getComparator(order, orderBy))
                  ?.map((data) => (
                    <TableRow
                      key={uuidv4()}
                      onContextMenu={(event) =>
                        handleContextMenu(event, data.id)
                      }
                      style={{
                        backgroundColor: getTableRowColor(data.id, rowId),
                      }}
                    >
                      <TableCell>{data?.name}</TableCell>

                      <TableCell>{data?.metadata.description}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledTable>
      <TablePagination
        rowsPerPageOptions={[20, 25, 50, 100]}
        component="div"
        count={Customers?.count || 0}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {anchorEl && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement="bottom-start"
        >
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClose}>
              <Fade {...TransitionProps}>
                <Paper>
                  <Styled>
                    <MenuItem onClick={() => setShowDialog(true)}>
                      <EditIcon />
                      Edit
                    </MenuItem>
                    <MenuItem
                      disabled={isCustomerDisable()}
                      onClick={() => setConfirmationDialog(true)}
                    >
                      <DeleteIcon />
                      Delete
                    </MenuItem>
                  </Styled>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      )}

      {showDialog && (
        <AddEditCustomer
          isOpen={showDialog}
          handleCloseDialog={handleCloseDialog}
          id={rowId}
        />
      )}
      {confirmationDialog && rowId && (
        <ConfirmationDialog
          isOpen={confirmationDialog}
          onClose={handleDeleteDialog}
          onConfirm={() => handleDeleteCustomer()}
          title="Delete Customer"
          confirmText="Confirm"
          isWarning
        >
          Are you sure you want to delete Customer ?
        </ConfirmationDialog>
      )}
    </Box>
  );
}
