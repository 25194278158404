/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";

type TokenState = {
  accessToken: string;
  refreshToken: string;
};

const initialState: TokenState = {
  accessToken: "",
  refreshToken: "",
};

export const tokenSlice = createSlice({
  initialState,
  name: "tokenSlice",
  reducers: {
    setTokens: (
      state,
      action: PayloadAction<{
        accessToken: string;
        refreshToken: string;
      }>
    ) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
  },
});

export const { setTokens } = tokenSlice.actions;
export const setTokenAuth = (state: RootState) => state.tokens;

export default tokenSlice.reducer;
