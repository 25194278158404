import React from "react";
import { FormHelperText } from "@mui/material";
import { HelperTextVariant } from "../../utils/enum/enum";

type HelperTextProps = {
  text: string;
  variant?: HelperTextVariant;
};
export default function HelperText({
  text,
  variant = HelperTextVariant.STANDARD,
}: HelperTextProps) {
  return (
    <FormHelperText variant={variant} sx={{ mt: 1 }}>
      {text}
    </FormHelperText>
  );
}
