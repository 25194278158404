import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
// import { useDispatch } from "react-redux";
import thunkMiddleware from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import reducer from "./rootReducer";
import appApi from "./api/appApi";

const persistConfig = {
  key: "store",
  storage,
  blacklist: [appApi.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      thunkMiddleware,
      appApi.middleware
    ),
  devTools: process.env.NODE_ENV !== "production",
});

//boiler for typescript 😀
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
// export const useAppDispatch = () => useDispatch<AppDispatch>();
