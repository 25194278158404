import React from "react";

import { Chip, Grid, TextField, Typography } from "@mui/material";
import format from "date-fns/format";
import CommonDialog from "../../../components/Dialog/CommonDialog";
import { useGetInstanceByIdQuery } from "../../../redux/api/instanceApi";

type InstanceStatusDialogueType = {
  isOpen: boolean;
  handleCloseDialog: () => void;
  id: string;
};
export default function InstanceStatusDialogue({
  isOpen,
  handleCloseDialog,
  id,
}: InstanceStatusDialogueType) {
  const { data: singleInstance } = useGetInstanceByIdQuery(id);
  return (
    <CommonDialog
      isOpen={isOpen}
      onClose={handleCloseDialog}
      title="Instance launch status"
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>is_launched</Typography>
          <Chip
            label={singleInstance?.is_launched === false ? "false" : "true"}
            size="small"
            color={singleInstance?.is_launched === false ? "error" : "success"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>status</Typography>
          <Chip label={singleInstance?.status} size="small" color="info" />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>state</Typography>
          <Chip
            label={
              singleInstance?.state === null ? "null" : singleInstance?.state
            }
            size="small"
            color={singleInstance?.state === null ? "warning" : "info"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>credentials_sent</Typography>
          <Chip
            label={
              singleInstance?.credentials_sent === false ? "false" : "true"
            }
            size="small"
            color={
              singleInstance?.credentials_sent === false ? "error" : "success"
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>created_at</Typography>
          <Chip
            label={
              singleInstance?.created_at
                ? format(
                    new Date(singleInstance?.created_at),
                    "dd MMMM yyyy HH:mm"
                  )
                : "Null"
            }
            size="small"
            color="info"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography>launched_at</Typography>
          <Chip
            label={
              singleInstance?.launched_at
                ? format(
                    new Date(singleInstance?.launched_at),
                    "dd MMMM yyyy HH:mm"
                  )
                : "Null"
            }
            size="small"
            color="info"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="health-check"
            name="health"
            value={JSON.stringify(singleInstance?.health_check_data, null, 2)}
            multiline
            rows={10}
            variant="outlined"
            label="Health Check Data"
          />
        </Grid>
      </Grid>
    </CommonDialog>
  );
}
