// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { FieldArray, FormikProps, FormikProvider } from "formik";

import Label from "../../../components/Label/Label";
import HelperText from "../../../components/HelperText/HelperText";
import customTheme from "../../../theme/customTheme";
import { InstanceType } from "../../../redux/api/api.types";
import StyledWrapperCard from "../../../styles/StyledWrapperCard";

type ResellersProps = {
  formik: FormikProps<InstanceType>;
};
export default function Resellers({ formik }: ResellersProps) {
  return (
    <FormikProvider value={formik}>
      <FieldArray
        name="subscription_metadata.resellers"
        render={(arrayHelpers) => (
          <>
            {formik?.values?.subscription_metadata?.resellers?.map(
              (reseller, index) => (
                <StyledWrapperCard key={index}>
                  <Grid container spacing={2}>
                    <Grid item xs={11}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Label text="Reseller first name" />
                          <TextField
                            fullWidth
                            id={`resellers.${index}.rs_firstname`}
                            name={`subscription_metadata.resellers.${index}.rs_firstname`}
                            value={reseller.rs_firstname}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Label text="Reseller last name" />
                          <TextField
                            fullWidth
                            id={`resellers.${index}.rs_lastname`}
                            name={`subscription_metadata.resellers.${index}.rs_lastname`}
                            value={reseller.rs_lastname}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Label text="Reseller e-mail" />
                          <TextField
                            fullWidth
                            id={`resellers.${index}.rs_email`}
                            name={`subscription_metadata.resellers.${index}.rs_email`}
                            value={reseller.rs_email}
                            onChange={formik.handleChange}
                            error={
                              formik?.touched?.subscription_metadata
                                ?.resellers?.[index]?.rs_email &&
                              Boolean(
                                (
                                  formik?.errors?.subscription_metadata
                                    ?.resellers?.[index] as {
                                    rs_email: string;
                                  }
                                )?.rs_email
                              )
                            }
                          />
                          {(
                            formik?.errors?.subscription_metadata?.resellers?.[
                              index
                            ] as { rs_email: string }
                          )?.rs_email && (
                            <HelperText
                              text={
                                (
                                  formik?.errors?.subscription_metadata
                                    ?.resellers?.[index] as {
                                    rs_email: string;
                                  }
                                )?.rs_email
                              }
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        pt: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {index !== 0 && (
                        <Box
                          onClick={() => arrayHelpers.remove(index)}
                          sx={{ textAlign: "center", alignSelf: "self-end" }}
                        >
                          <DeleteOutlineOutlinedIcon
                            sx={{
                              fontSize: "2rem",
                              color: customTheme.palette.error.main,
                              cursor: "pointer",
                            }}
                          />
                          <Typography
                            variant="body1"
                            sx={{
                              pt: 0.5,
                              color: customTheme.palette.error.main,
                              cursor: "pointer",
                            }}
                          >
                            Remove
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </StyledWrapperCard>
              )
            )}
            <Grid
              item
              xs={12}
              sx={{ display: "inline-block", verticalAlign: "middle", ml: 2 }}
            >
              <Button
                type="button"
                onClick={() =>
                  arrayHelpers.push({
                    rs_email: "",
                    rs_firstname: "",
                    rs_lastname: "",
                  })
                }
              >
                + ADD ANOTHER RESELLER CONTACT
              </Button>
            </Grid>
          </>
        )}
      />
    </FormikProvider>
  );
}
