import React, { useState } from "react";
import { useFormik } from "formik";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useSnackbar } from "notistack";
import {
  styled,
  Grid,
  Avatar,
  InputAdornment,
  CircularProgress,
  IconButton,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  WarningAmberOutlined,
  CheckCircleOutlineOutlined,
} from "@mui/icons-material";
import { PASSWORD_RULES } from "../../utils/constants";
import CustomAlert from "../../components/CustomAlert/CustomAlert";
import customTheme from "../../theme/customTheme";
import { UIComponentColorVariants } from "../../utils/enum/enum";
import { useChangePasswordMutation } from "../../redux/api/userApi";
import { changePasswordValidationSchema } from "../../utils/validations/validations";
import { useAppSelector } from "../../hooks/storeHooks";
import { setUserAuth } from "../../redux/userSlice";
import { useCreateErrorMessage } from "../../hooks/useCreateErrorMessage";

const ResetWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(4),
  "& .MuiFormControl-root": {
    width: "100%",
    marginBottom: "1rem",
    "&:last-child": {
      marginBottom: "0",
    },
  },
}));

const FormTitle = styled("div")(({ theme }) => ({
  height: "2rem",
  marginBottom: theme.spacing(2),
}));

function ChangePassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [changedPassword, { isLoading }] = useChangePasswordMutation();
  const { user } = useAppSelector(setUserAuth);
  const { enqueueSnackbar } = useSnackbar();
  const { createErrorMessage } = useCreateErrorMessage();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: changePasswordValidationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      changedPassword({
        id: user!.id,
        old_password: values.old_password,
        new_password: values.new_password,
      })
        .unwrap()
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then(() => {
          enqueueSnackbar(`Password changed successful`, {
            variant: "success",
            autoHideDuration: 10000,
            preventDuplicate: true,
          });
          resetForm();
          setSubmitting(false);
        })
        .catch((error) => {
          createErrorMessage({
            error,
            message: "Failed to change the password.",
          });
          setSubmitting(false);
        });
    },
  });

  return (
    <Paper elevation={3} sx={{ width: 500, margin: "50px auto auto auto" }}>
      <ResetWrapper>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mb: "1rem" }}
        >
          <Grid item>
            <Avatar sx={{ m: 1, bgcolor: "#012169" }}>
              <LockOutlinedIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <FormTitle>
              <Typography variant="h5">Change Password</Typography>
            </FormTitle>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              <CustomAlert
                title="info"
                severity={UIComponentColorVariants.INFO}
              >
                Please enter old and new password. You can log in with the new
                password when the change is successful.
              </CustomAlert>
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} sx={{ pb: 2 }}>
              <TextField
                fullWidth
                id="old_password"
                name="old_password"
                type={showPassword ? "text" : "password"}
                sx={{ width: "100%", minWidth: "100%", marginBottom: "0.5rem" }}
                value={formik.values.old_password}
                onChange={formik.handleChange}
                label="Old Password"
                error={
                  formik.touched.old_password &&
                  Boolean(formik.errors.old_password)
                }
                helperText={
                  formik?.touched?.old_password && formik?.errors?.old_password
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ pb: 2 }}>
              <TextField
                fullWidth
                id="new_password"
                name="new_password"
                type={showPassword ? "text" : "password"}
                sx={{ width: "100%", minWidth: "100%", marginBottom: "0.5rem" }}
                value={formik.values.new_password}
                onChange={formik.handleChange}
                label="New Password"
                error={
                  formik.touched.new_password &&
                  Boolean(formik.errors.new_password)
                }
                helperText={
                  formik?.touched?.new_password && formik?.errors?.new_password
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {formik.values.new_password.length === 0 && (
                <Typography variant="body1" sx={{ fontSize: "0.8rem" }}>
                  Password should contain at least 10 characters, it must
                  contain special characters, uppercase letters and numbers.
                </Typography>
              )}
              {formik.values.new_password.length !== 0 &&
                formik.errors.new_password && (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        color:
                          formik.values.new_password.length < 10
                            ? customTheme.palette.error.main
                            : customTheme.palette.success.main,
                      }}
                    >
                      {formik.values.new_password.length < 10 ? (
                        <WarningAmberOutlined
                          sx={{
                            verticalAlign: "sub",
                            fontSize: "1.2rem",
                            mr: "0.3rem",
                          }}
                        />
                      ) : (
                        <CheckCircleOutlineOutlined
                          sx={{
                            verticalAlign: "sub",
                            fontSize: "1.2rem",
                            mr: "0.3rem",
                          }}
                        />
                      )}
                      10+ characters
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: formik.values.new_password.match(
                          new RegExp(PASSWORD_RULES.UPPERCASE_REGEX)
                        )
                          ? customTheme.palette.success.main
                          : customTheme.palette.error.main,
                      }}
                    >
                      {formik.values.new_password.match(
                        new RegExp(PASSWORD_RULES.UPPERCASE_REGEX)
                      ) ? (
                        <CheckCircleOutlineOutlined
                          sx={{
                            verticalAlign: "sub",
                            fontSize: "1.2rem",
                            mr: "0.3rem",
                          }}
                        />
                      ) : (
                        <WarningAmberOutlined
                          sx={{
                            verticalAlign: "sub",
                            fontSize: "1.2rem",
                            mr: "0.3rem",
                          }}
                        />
                      )}
                      At least one uppercase character
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: formik.values.new_password.match(
                          new RegExp(PASSWORD_RULES.SPECIAL_CHAR_REGEX)
                        )
                          ? customTheme.palette.success.main
                          : customTheme.palette.error.main,
                      }}
                    >
                      {formik.values.new_password.match(
                        new RegExp(PASSWORD_RULES.SPECIAL_CHAR_REGEX)
                      ) ? (
                        <CheckCircleOutlineOutlined
                          sx={{
                            verticalAlign: "sub",
                            fontSize: "1.2rem",
                            mr: "0.3rem",
                          }}
                        />
                      ) : (
                        <WarningAmberOutlined
                          sx={{
                            verticalAlign: "sub",
                            fontSize: "1.2rem",
                            mr: "0.3rem",
                          }}
                        />
                      )}
                      At least one special character
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: formik.values.new_password.match(
                          new RegExp(PASSWORD_RULES.NUMBER_REGEX)
                        )
                          ? customTheme.palette.success.main
                          : customTheme.palette.error.main,
                      }}
                    >
                      {formik.values.new_password.match(
                        new RegExp(PASSWORD_RULES.NUMBER_REGEX)
                      ) ? (
                        <CheckCircleOutlineOutlined
                          sx={{
                            verticalAlign: "sub",
                            fontSize: "1.2rem",
                            mr: "0.3rem",
                          }}
                        />
                      ) : (
                        <WarningAmberOutlined
                          sx={{
                            verticalAlign: "sub",
                            fontSize: "1.2rem",
                            mr: "0.3rem",
                          }}
                        />
                      )}
                      At least one number
                    </Typography>
                  </>
                )}
            </Grid>
            <Grid item xs={12} sx={{ pb: 2 }}>
              <TextField
                fullWidth
                id="confirm_password"
                name="confirm_password"
                type={showPassword ? "text" : "password"}
                sx={{ width: "100%", minWidth: "100%", marginBottom: "0.5rem" }}
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                label="Confirm Password"
                error={
                  formik.touched.confirm_password &&
                  Boolean(formik.errors.confirm_password)
                }
                helperText={
                  formik?.touched?.confirm_password &&
                  formik?.errors?.confirm_password
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            size="large"
            fullWidth
            sx={{
              backgroundColor: customTheme.palette.primary.main,
              color: "#fff",
              mt: "1rem",
            }}
            type="submit"
            disabled={formik.isSubmitting || !formik.dirty}
            startIcon={
              formik.isSubmitting || isLoading ? (
                <CircularProgress size="1rem" />
              ) : undefined
            }
          >
            Change Password
          </Button>
        </form>
      </ResetWrapper>
    </Paper>
  );
}

export default ChangePassword;
