import * as React from "react";
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from "@mui/material/Checkbox";
import { FieldProps } from "formik";

export interface CheckboxProps
  extends FieldProps,
    Omit<
      MuiCheckboxProps,
      | "name"
      | "value"
      | "error"
      | "form"
      | "checked"
      | "defaultChecked"
      // Excluded for conflict with Field type
      | "type"
    > {
  type?: string;
}

export function fieldToCheckbox({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting },
  onBlur,
  ...props
}: CheckboxProps): MuiCheckboxProps {
  const indeterminate = !Array.isArray(field.value) && field.value == null;

  return {
    disabled: disabled ?? isSubmitting,
    indeterminate,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name);
      },
    ...field,
    ...props,
  };
}

export function Checkbox(props: CheckboxProps) {
  return <MuiCheckbox {...fieldToCheckbox(props)} />;
}

Checkbox.displayName = "FormikMaterialUICheckbox";
