import React from 'react'
import { Link, List, ListItem, Typography } from '@mui/material';
import { PALATTE_SUCCESS_BRIGHT } from '../../../utils/constants/constantColor';

const tooltip = (
  <>
    <Typography variant="body1" gutterBottom>
      Select the encoding method for the instance. You can select up to 4 encoding methods.
    </Typography>
    <Typography variant="body2" paragraph>
      If you have selected <strong>SGTIN96</strong>, you can add both <strong>Company Prefix</strong> and <strong>Partition</strong> according to the table below:
    </Typography>
    <List sx={{ pt: 1, pb: 2, pl: 5, pr: 5 }}>
      {[
        { partition: 0, companyPrefix: 40 },
        { partition: 1, companyPrefix: 37 },
        { partition: 2, companyPrefix: 34 },
        { partition: 3, companyPrefix: 30 },
        { partition: 4, companyPrefix: 27 },
        { partition: 5, companyPrefix: 24 },
        { partition: 6, companyPrefix: 20 },
      ].map((item) => (
        <ListItem
          key={item.partition}
          disableGutters
          sx={{ display: "list-item", padding: 0 }}
        >
          Partition <strong style={{ color: PALATTE_SUCCESS_BRIGHT }}>{item.partition}</strong>: Company Prefix <strong style={{ color: PALATTE_SUCCESS_BRIGHT }}>{item.companyPrefix}</strong> bits
        </ListItem>
      ))}
    </List>
    <Typography variant="body2" sx={{ display: "flex", flexDirection: "column" }}>
      Link to more information on SGTIN encoding:{" "}
      <Link href="https://www.epc-rfid.info/sgtin-partition-values" target="_blank" rel="noopener">
        1. Partition table.
      </Link>
      <Link href="https://www.epc-rfid.info/sgtin" target="_blank" rel="noopener">
        2. Sgtin info
      </Link>
    </Typography>
  </>
);
export default function EncodingTooltip() {
  return (
    tooltip
  )
}

