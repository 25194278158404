import React from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useCreateErrorMessage } from "../../../hooks/useCreateErrorMessage";
import CommonDialog from "../../../components/Dialog/CommonDialog";
import ActionDialog from "../../../components/Dialog/ActionDialog";
import {
  useCreateCustomerMutation,
  useEditCustomerMutation,
  useGetCustomerByIdQuery,
} from "../../../redux/api/customerApi";

type AddEditCustomerProps = {
  id?: string;
  isOpen: boolean;
  handleCloseDialog: () => void;
};
const initialValues = {
  name: "",
  metadata: { description: "" },
};

export default function AddEditCustomer({
  id,
  isOpen,
  handleCloseDialog,
}: AddEditCustomerProps) {
  const [createCustomer] = useCreateCustomerMutation();
  const { data: customer } = useGetCustomerByIdQuery(id);
  const [editCustomer] = useEditCustomerMutation();

  const { createErrorMessage } = useCreateErrorMessage();

  if (id && customer) {
    initialValues.name = customer?.name;
    initialValues.metadata.description = customer?.metadata.description;
  }
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      // eslint-disable-next-line no-unused-expressions
      id
        ? editCustomer({ id, patch: values })
            .unwrap()
            .then((payload) => {
              if (payload) {
                enqueueSnackbar("success", {
                  variant: "success",
                  autoHideDuration: 2000,
                });
                handleCloseDialog();
              }

              navigate("/customer");
            })
            .catch((error) => {
              createErrorMessage({
                error,
                message: "Failed to update a customer.",
              });
              handleCloseDialog();
            })
        : createCustomer(values)
            .unwrap()
            .then((payload) => {
              if (payload) {
                enqueueSnackbar("success", {
                  variant: "success",
                  autoHideDuration: 2000,
                });
                handleCloseDialog();
              }
              navigate("/customer");
            })
            .catch((error) => {
              createErrorMessage({
                error,
                message: "Failed to create a new customer.",
              });
            });
    },
  });

  return (
    <CommonDialog
      isOpen={isOpen}
      onClose={handleCloseDialog}
      title={id ? "Edit Customer" : "Add Customer"}
    >
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="Customer"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          fullWidth
          id="description"
          name="metadata.description"
          label="Description"
          value={formik.values.metadata.description}
          onChange={formik.handleChange}
          error={
            formik.touched.metadata?.description &&
            Boolean(formik.errors.metadata?.description)
          }
          helperText={
            formik.touched.metadata?.description &&
            formik.errors.metadata?.description
          }
        />
        <ActionDialog onClose={handleCloseDialog} />
      </form>
    </CommonDialog>
  );
}
