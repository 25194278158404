// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/no-array-index-key */
import React from "react";
import { FormikProps } from "formik";
import { FormControl, MenuItem, Select, Grid, Box } from "@mui/material";
import {} from "../../../utils/validations/validations";
import HelperText from "../../../components/HelperText/HelperText";
import Label from "../../../components/Label/Label";
import BasicTooltip from "../../../components/BasicTooltip/BasicTooltip";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { ExtensionApiResponse } from "../../../redux/api/api.types";
import { InitialValues } from "./integrationFormTypes";
import { validateVersion } from "../../../utils/functions/functions";
import { ApplicationTypes, HelperTextVariant } from "../../../utils/enum/enum";

export type ExtensionsType = {
  formik: FormikProps<InitialValues>;
  extensionData: ExtensionApiResponse | undefined;
  deployedTagName: string | undefined;
};

export default function BackendApplication({
  formik,
  extensionData,
  deployedTagName,
}: ExtensionsType) {
  // Processing for form
  const extensionLocal = extensionData?.results.filter(
    (item) => item.name === ApplicationTypes.BACKEND
  );
  const extensionsNames = extensionLocal?.map(({ name }) => ({
    key: name,
    value: name,
  }));

  return (
    <Grid item xs={12} sx={{ pb: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Label text="Backend Application Name" />
          <FormControl fullWidth>
            <Select
              labelId="backendApplication-id"
              id="backendApplication-id"
              name="backendApplication.backendApplication_name"
              value={formik.values.backendApplication.backendApplication_name}
              onChange={(event) => {
                formik.setFieldValue(
                  `backendApplication.backendApplication_name`,
                  event.target.value
                );
              }}
            >
              {extensionsNames &&
                extensionsNames.map((extension) => (
                  <MenuItem key={extension.key} value={extension.value}>
                    {extension.value}
                  </MenuItem>
                ))}
            </Select>
            {(
              formik?.errors.backendApplication as any as {
                backendApplication_name: string;
              }
            )?.backendApplication_name && (
              <HelperText
                text={
                  (
                    formik?.errors.backendApplication as any as {
                      backendApplication_name: string;
                    }
                  )?.backendApplication_name
                }
              />
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Label text="Version" />
          <Box sx={{ display: "inline-block", verticalAlign: "middle", ml: 1 }}>
            <BasicTooltip text="If no version is selected for first time instance launch, latest version will be deployed automatically" />
          </Box>
          <Box display="flex">
            <FormControl fullWidth>
              <Select
                labelId="backendApplication-id"
                id="backendApplication-id"
                name="backendApplication.tag"
                value={formik.values.backendApplication.tag}
                onChange={(event) => {
                  const selectedTag = extensionData?.results
                    .find((item) => item.name === ApplicationTypes.BACKEND)
                    ?.tags.find((tag) => tag.id === event.target.value);

                  formik.setFieldValue(
                    `backendApplication.tag`,
                    event.target.value
                  );
                  formik.setFieldValue(
                    `backendApplication.name`,
                    selectedTag ? selectedTag.name : ""
                  );
                }}
              >
                {(
                  extensionData?.results.find(
                    (item) => item.name === ApplicationTypes.BACKEND
                  )?.tags || []
                ).map((tag) => (
                  <MenuItem key={tag.name} value={tag.id}>
                    {tag.name}
                  </MenuItem>
                ))}
              </Select>
              <HelperText
                text={validateVersion(
                  deployedTagName,
                  formik.values.backendApplication.name
                )}
                variant={HelperTextVariant.OUTLINED}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
