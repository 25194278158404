import React from 'react'
import { Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FormikProps } from 'formik';
import Label from "../../../components/Label/Label";
import { InstanceType } from "../../../redux/api/api.types";

type EncodingDefaultSelectProps = {
  formik: FormikProps<InstanceType>;
};

export default function EncodingDefaultSelect({ formik }: EncodingDefaultSelectProps) {
  // Default encoding
  // Find the current default encoding method
  const defaultEncodingMethod = formik.values.encodings.find(
    (enc) => enc.default === true
  )?.method || "";

  // Handle the change for the default encoding method
  const handleDefaultSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedMethod = event.target.value as string;

    // Update the 'default' value for the selected encoding method
    const updatedEncodingMethods = formik.values.encodings.map((enc) =>
      enc.method === selectedMethod ? { ...enc, default: true } : { ...enc, default: false }
    );

    formik.setFieldValue("encodings", updatedEncodingMethods);
  };
  return (
    <Grid item xs={3}>
      <Label text="Default" />
      <Select
        fullWidth
        name="encodings.default"
        labelId="default-encoding-label"
        id="default-encoding-select"
        value={defaultEncodingMethod}
        onChange={handleDefaultSelectChange}
      >
        {formik.values.encodings.map((option, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <MenuItem key={`${option.method}-${index}`} value={option.method}>
            {option.method}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  )
}
